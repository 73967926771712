import React from 'react';
import i18n from '../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle, Typography, Checkbox, DialogContentText, FormGroup } from '@material-ui/core';
import { wentRight, wentWrong } from "../../../utils/ToastConfig";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from '../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import {availableSurface } from "../../../utils/ParcelArea";
export default class AddMachine extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            masina_grupa: null, machineGroupType: [],
            masina_podgrupa: null, machineSubGroupType: [],
            masina: null, machineType: [],
            gps_provajder: null, gpsProvajderType: [],
            gps_broj_uredjaja: null, registarska_oznaka: null,
            machine_disabled: true, machine_subgroup_disabled: true,
            id: null,
            
            add: this.props.add, edit: this.props.edit,
        };
    };

    onInputchange(event) {
        this.setState({
        });
      }

    componentDidMount() {
        
        this.getByURL('machine_group/drop_down', 'machineGroupType');
        if(this.props.id_pogonska_masina_podgrupa)  this.getByURL('machine_subgroup/drop_down', 'machineSubGroupType');
        if (this.props.id_pogonska_masina) this.getByURL('machine/drop_down', 'machineType');
        this.getByURL('gps/get_all', 'gpsProvajderType');
     


        if (this.state.edit === true) {

            axios.get('api/machine_subgroup/drop_down',
                { params: { id_grupa: this.props.data.id_pogonska_masina_grupa } }
            )
                .then(res => { this.setState({ machineSubGroupType: res.data.data, machine_subgroup_disabled: false }); })
                .catch(err => { console.log(err) })

            axios.get('api/machine/drop_down',
                { params: { id_podgrupa: this.props.data.id_pogonska_masina_podgrupa } }
            )
                .then(res => { this.setState({ machineType: res.data.data, machine_disabled: this.props.data.id_pogonska_masina_podgrupa ?  false :  true }); })
                .catch(err => { console.log(err) })


            this.setState({
                naziv: this.props.data.naziv,
                id: this.props.data.id,

                masina_grupa: this.props.data.id_pogonska_masina_grupa,
                masina_podgrupa: this.props.data.id_pogonska_masina_podgrupa,
                masina: this.props.data.id_pogonska_masina,

                gps_provajder: this.props.data.id_gps_provajder,
                gps_broj_uredjaja: this.props.data.gps_broj_uredjaja,
                registarska_oznaka: this.props.data.registarska_oznaka,
                
            });
        }
    };

    getByURL(url, obj, event, required) {
        event = event || '';
        required = required || '';
        if (event != null) {
            axios.get(`api/${url}`, { params: { [required]: event } })
                .then(res => this.setState({ [obj]: res.data.data }))
                .catch(error => wentWrong('Something went wrong, please try again!'));
        }
    };

    addMachine() {
        if (this.state.naziv) {
                const { onSave, handleClose } = this.props;

                let data = {};

                if (this.state.edit === false ) {
                    data = {
                        id_pogonska_masina: (this.state.masina),
                        id_pogonska_masina_grupa: (this.state.masina_grupa),
                        id_pogonska_masina_podgrupa: (this.state.masina_podgrupa),
                        naziv: this.state.naziv,
                        id_gps_provajder: this.state.gps_provajder,
                        gps_broj_uredjaja: this.state.gps_broj_uredjaja,
                        registarska_oznaka: this.state.registarska_oznaka,
                    };

                    axios.post('api/machine/create_machine', data)
                        .then(res => {
                            wentRight(i18n.t('Successfully added machine'));
                            handleClose();
                        })
                        .catch(err => {
                            console.log(err);
                            wentWrong(i18n.t('Something went wrong, please try again!'))
                        })
                }
                else if (this.state.edit === true) {

                    data = {
                        id: (this.props.data.id),
                        id_pogonska_masina: (this.state.masina),
                        id_pogonska_masina_grupa: (this.state.masina_grupa),
                        id_pogonska_masina_podgrupa: (this.state.masina_podgrupa),
                        naziv: this.state.naziv,
                        id_gps_provajder: this.state.gps_provajder,
                        gps_broj_uredjaja: this.state.gps_broj_uredjaja,
                        registarska_oznaka: this.state.registarska_oznaka
                    };

                    axios.post('api/machine/create_machine', data)
                        .then(res => {
                            wentRight(i18n.t('Successfully added machine'));
                            handleClose();
                        })
                        .catch(err => {
                            console.log(err);
                            wentWrong(i18n.t('Something went wrong, please try again!'))
                        })
                }

        } else wentWrong('Please enter all required fields!');
    };

    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    }

    closeEditDialog = () => {
        this.props.handleClose()
    }

    render() {
        console.log(this.props.data)
        return (
            <div style={{ padding: 20 }}>
                <Dialog open={this.state.add}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle disableTypography={true} style={{ background: '#04764e' }} className="responsive-dialog-title">{!this.state.edit ? i18n.t('Add machine') : i18n.t('Edit Machine')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                            <TextField required
                                label={i18n.t('Title')}
                                value={this.state.naziv}
                                style={{ width: '532px', marginBottom: '10px', marginTop: '20px', marginLeft: '10px' }}
                                InputLabelProps={{ shrink: this.state.naziv ? true : false }}
                                onChange={(e) => { this.setState({ naziv: e.target.value, field: null }); }} >
                            </TextField>
                        
                            <TextField
                                label={i18n.t('Implements group')}
                                select
                                value={this.state.masina_grupa}
                                SelectProps={{ native: true }}
                                InputLabelProps={{ shrink: this.state.masina_grupa ? true : false }}
                                style={{ width: '256px', marginBottom: '10px', marginLeft: '10px' }}
                                onChange={(e) => {
                                    this.setState({ masina_grupa: e.target.value }, () => {
                                        axios.get('api/machine_subgroup/drop_down',
                                            { params: { id_grupa: this.state.masina_grupa } }
                                        )
                                            .then(res => { this.setState({ machineSubGroupType: res.data.data, machine_subgroup_disabled: false}); })
                                            .catch(err => { console.log(err) })
                                    });
                                }} >
                                <option value='' />
                                {this.state.machineGroupType.map((mg) => <option key={mg.id} value={mg.id}>{mg.naziv}</option>)}
                            </TextField>

                            <TextField
                                label={i18n.t('Implements subgroup')}
                                select
                                disabled={this.state.machine_subgroup_disabled}
                                value={this.state.masina_podgrupa}
                                SelectProps={{ native: true }}
                                InputLabelProps={{ shrink: this.state.masina_podgrupa ? true : false }}
                                style={{ width: '256px', marginBottom: '10px', marginLeft: '10px' }}
                                onChange={(e) => {
                                    this.setState({ masina_podgrupa: e.target.value }, () => {;
                                    axios.get('api/machine/drop_down',
                                            { params: { id_podgrupa: this.state.masina_podgrupa } }
                                        )
                                            .then(res => { this.setState({ machineType: res.data.data, machine_disabled: false}); })
                                            .catch(err => { console.log(err) })
                                    });

                                }} >
                                <option value='' />
                                {this.state.machineSubGroupType.map((msg) => <option key={msg.id} value={msg.id}>{msg.naziv}</option>)}
                            </TextField>

                            <TextField
                                label={i18n.t('Model')}
                                select
                                disabled={this.state.machine_disabled}
                                value={this.state.masina}
                                SelectProps={{ native: true }}
                                InputLabelProps={{ shrink: this.state.masina ? true : false }}
                                style={{ width: '256px', marginBottom: '10px', marginLeft: '10px' }}
                                onChange={(e) => {
                                    this.setState({ masina: e.target.value }, () => {});
                                }} >
                                <option value='' />
                                {this.state.machineType.map((msg) => <option key={msg.id} value={msg.id}>{msg.naziv}</option>)}
                            </TextField>

                            <TextField
                                label={i18n.t('Licence number')}
                                value={this.state.registarska_oznaka}
                                style={{ width: '256px', marginBottom: '10px', marginLeft: '10px' }}
                                InputLabelProps={{ shrink: this.state.registarska_oznaka ? true : false }}
                                onChange={(e) => { 
                                    this.setState({ registarska_oznaka: e.target.value }, () => {});
                                    }} >
                            </TextField>

                            <TextField
                                label={i18n.t('GPS provider')}
                                select
                                value={this.state.gps_provajder}
                                SelectProps={{ native: true }}
                                InputLabelProps={{ shrink: this.state.gps_provajder ? true : false }}
                                style={{ width: '256px', marginBottom: '10px', marginLeft: '10px' }}
                                onChange={(e) => {
                                    this.setState({ gps_provajder: e.target.value }, () => {});
                                }} >
                                <option value='' />
                                {this.state.gpsProvajderType.map((gpsp) => <option key={gpsp.id} value={gpsp.id}>{gpsp.naziv}</option>)}
                            </TextField>

                            <TextField
                                label={i18n.t('GPS device number')}
                                value={this.state.gps_broj_uredjaja}
                                style={{ width: '256px', marginBottom: '10px', marginLeft: '10px' }}
                                InputLabelProps={{ shrink: this.state.gps_broj_uredjaja ? true : false }}
                                onChange={(e) => { 
                                    this.setState({ gps_broj_uredjaja: e.target.value }, () => {});
                                    }} >
                            </TextField>

                        <div style={{ display: 'flex' }}>
                            <Button style={{ marginLeft: '10px', marginTop: '20px' }} variant="contained" color="primary" onClick={() => {
                                this.addMachine();
                            }}>
                                {this.state.edit ? i18n.t('Save') : i18n.t('Add')}
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>
            </div >
        );
    };
};