import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import axios from '../../../utils/AxiosWrapper';
import { wentWrong } from '../../../utils/ToastConfig';
import Select from 'react-select';
import i18n from '../../../i18n/i18n';
import immutable from 'seamless-immutable';
import AddMaterial from './AddMaterial';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import ForBooleanFilter from '../../../utils/ForBooleanFilter';
import { withStyles } from '@material-ui/core/styles';
import {
    IconButton, Tooltip,
    Button, DialogActions,
    Dialog, DialogContent, DialogTitle, Grid
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import * as Icons from '@material-ui/icons';
import { Delete } from "../../../utils/Delete";
import { getUser } from '../../../utils/CommonFunctions';
import SearchBar from 'material-ui-search-bar';
import AddIcon from '@material-ui/icons/Add';
import { Tooltip as Tooltip2 } from '@material-ui/core';
import {  PulseLoader } from 'react-spinners';

const CheckboxTable = checkboxHOC(ReactTable);
const commonHeaderStyle = {
    textAlign: 'center',
    textTransform: 'uppercase',
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: '#04764e',
    color: "#ffff",

};
const styles = theme => ({
    searchWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px 30px 10px 30px',
    },
    primaryMain: {
        backgroundColor: theme.palette.primary.main,
    },
    center: {
        textAlign: 'center'
    },
    starButton: {
        padding: '3px',
        // color: '#f2ce3e'
    },
    addNewBtn: {
        width: 'fit-content',
        height: 40,
        padding: '0 15px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 0,
        backgroundColor: '#5EB3E4',
        '&:hover': {
            backgroundColor: '#4FA2CD',
        },
    },
    paginationButton: {
        borderRadius: '50%',
        width: '30px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#5EB3E4',
        color: 'white',
        border: 'none',
        cursor: 'pointer'
    },
    tableCell:{
        textAlign: 'center',
        paddingLeft: '10px',
        height: '50px',
        alignItems: 'center',
        overflowY: 'hidden',
        overflowX: 'hidden'
    },
    dropDowns: {
        backgroundColor: '#F4F6F7',
        color: '#333333',
        fontSize: '14px',
        border: 'none',
        borderBottom: '1px solid #333333',
        marginRight: '20px',
        cursor: 'pointer',
        '&:focus': {
            outline: 'none',
        },
    },
    filtersSection: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
        paddingLeft: '30px',
        paddingTop: '18px',
    },
});
export class ViewMaterials extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //add: this.props.add,
            //edit: this.props.edit,
            // showFilters: this.props.showFilters,
            selection: [],
            selectAll: false,
            material: [],
            deleteFavorite: false,
            user: {},
            cena: null,
            //-----------
            deleteMaterialPopUp: false,
            deleteMaterialId: -1,
            deleteMaterialName: "",
            searchBarValue: "",
            data: [],
            totalPages: 0,
            page: 0,
            pageSize: 10,
            sorted: null, 
            filtered: [],
            load: true,
            allGroups: [],
            selectedGroup: "",
            allSubGroups: [],
            selectedSubGroup: "",
            allUnits: [],
            selectedUnit: "",
            showAddMaterial: false,
            showEditMaterial: false,
            materialObjEdit: null,
            id_klijent_vlasnik: -1
        };
    }

    componentDidMount() {

        this.fetchAllMaterialGroups();
        this.fetchAllMaterialSubGroups();
        this.fetchAllMaterialUnits();

        // if (this.props.materialGroupDropdown.length === 0) {
        //     this.props.getMaterialGroup();
        // }
        // if (this.props.materialSubgroupDropdown.length === 0) {
        //     this.props.getMaterialSubgroup();
        // }
        // if (this.props.crops.length === 0) {
        //     this.props.getCrop();
        // }
        // if (this.props.unit.length === 0) {
        //     this.props.getUnit();
        // }
        this.setState({
            user: getUser()
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedUnit !== this.state.selectedUnit || 
            prevState.selectedGroup !== this.state.selectedGroup ||
            prevState.selectedSubGroup !== this.state.selectedSubGroup ||
            prevState.searchBarValue !== this.state.searchBarValue
        ) {
            // if (!this.state.isFirstRender) this.setState({ currentPage: 0 });
            this.state.load = true;
            this.reactTable.fireFetchData();
        }
    }

    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.deleted === true) {
    //         const wrapped = this.checkboxTable.getWrappedInstance();
    //         this.props.getMaterial(wrapped.state);
    //         this.setState({
    //             selection: [],
    //             selectAll: false,
    //         })
    //         this.props.setInitialState('deleted');

    //     }

    //     if (nextProps.addFavoriteSuccess) {
            // const wrapped = this.checkboxTable.getWrappedInstance(); ---
            // this.props.getMaterial(wrapped.state); ---
        //     this.props.setInitialState('addFavoriteSuccess');
        // }

        // if (nextProps.materialPostingSuccess === true) {
        //     this.setState({
        //         selection: [],
        //         name: '',
        //         selectAll: false
        //     })
        //     this.props.handleClose();
            // const wrapped = this.checkboxTable.getWrappedInstance(); ---
            // this.props.getMaterial(wrapped.state); ----
        //     this.props.setInitialState('materialPostingSuccess');
        // }
        // this.setState({
        //     add: nextProps.add,
        //     showFilters: nextProps.showFilters,
        //     material: nextProps.material,
        //     cena: nextProps.materialObj.cena,
        //     materialObj: nextProps.materialObj,
        //     materialsFetching: nextProps.materialsFetching,
        //     materialGroupDropdown: immutable.asMutable(nextProps.materialGroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
        //     groupFetching: nextProps.groupFetching,
        //     groupFetchingFailed: nextProps.groupFetchingFailed,
        //     materialSubgroupDropdown: immutable.asMutable(nextProps.materialSubgroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
        //     subgroupFetching: nextProps.subgroupFetching,
        //     subgroupFetchingFailed: nextProps.subgroupFetchingFailed,
        //     crops: immutable.asMutable(nextProps.crops.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
        //     cropFetching: nextProps.cropFetching,
        //     cropFetchingFailed: nextProps.cropFetchingFailed,
        //     unit: immutable.asMutable(nextProps.unit.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
        //     unitFetching: nextProps.unitFetching,
        //     unitFetchingFailed: nextProps.unitFetchingFailed,
        // })
    //     if (nextProps.add == true && nextProps.edit == false) {
    //         this.props.setInitialState('materialObj');
    //         this.setState({
    //             selection: [],
    //             name: '',
    //             selectAll: false
    //         })
    //     }
    // }

    getData(state) {
        let filtered = [...this.state.filtered];

        this.setState({                 
            load: true
        });

        if (this.state.searchBarValue) {
            filtered.push({ id: 'naziv', value: this.state.searchBarValue });
        }
        if (this.state.selectedUnit) {
            filtered.push({ id: 'unit', value: this.state.selectedUnit });
        }

        if (this.state.selectedGroup) {
            filtered.push({ id: 'id_materijal_grupa', value: this.state.selectedGroup });
        }

        if (this.state.selectedSubGroup) {
            filtered.push({ id: 'id_materijal_podgrupa', value: this.state.selectedSubGroup });
        }

        this.setState({ data: [] });
        axios.get(`api/material/read`, {
            params: { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: filtered }
        })
            .then(res => {
                if(res.data.data.length> 0){
                    this.setState({
                        id_klijent_vlasnik: res.data.data[0].id_klijent_vlasnik,
                    })
                }
                this.setState({
                    data: res.data.data,
                    totalPages: res.data.total_pages,                  
                    load: false
                }
            );
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 1 (getData): ', error);
            })
    }
    fetchAllMaterialGroups() {
        axios.get(`api/material_group/drop_down_for_materials`)
        .then(res => {
            const groups = res.data.data
                .map(item => ({ id: item.id, value: item.naziv }));
            this.setState({ allGroups: groups,
             });
        })
            .catch(() => wentWrong(i18n.t('Something went wrong')))
    }

    fetchAllMaterialSubGroups() {
        axios.get(`api/material_subgroup/drop_down_for_materials`)
        .then(res => {
            const sub_groups = res.data.data
                .map(item => ({ id: item.id, value: item.naziv }));
            this.setState({ allSubGroups: sub_groups,
             });
        })
            .catch(() => wentWrong(i18n.t('Something went wrong')))
    }

    fetchAllMaterialUnits() {
        axios.get(`api/unit/drop_down_for_materials`)
        .then(res => {
            const units = res.data.data
                .map(item => ({ id: item.id, value: item.naziv }));
            this.setState({ allUnits: units,
             });
        })
            .catch(() => wentWrong(i18n.t('Something went wrong')))
    }

    addMaterialToFavorite(id_materijal,cena) {

            axios.post(`api/material/addFavorite`, { id_materijal,cena })
                .then(res => {
                    console.log("Successfully added to favorites.");
                })
                .catch(() => wentWrong(i18n.t('Something went wrong')));
        
    }

    deleteMaterialFavorite(id_materijal) {

        axios.post(`api/material/deleteFavorite`, { id_materijal })
            .then(res => {
                console.log("Successfully removed from favorites.");
            })
            .catch(() => wentWrong(i18n.t('Something went wrong')));
    }

    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    // toggleSelection = (key, shift, row) => {
    //     let selection = [...this.state.selection];
    //     const keyIndex = selection.indexOf(key);
    //     if (keyIndex >= 0) {
    //         selection = [
    //             ...selection.slice(0, keyIndex),
    //             ...selection.slice(keyIndex + 1)
    //         ];
    //     } else {
    //         selection.push(key);
    //     }
    //     this.setState({ selection }, function () {
    //         if (selection.length == 1) {
    //             let row1 = this.props.material.filter((mat) => {
    //                 return mat.id == selection;
    //             })
    //             this.setState({ row: null })
    //             this.props.setRowInReducer(row1[0], 'materialObj');
    //         }
    //     });
    //     this.props.setSelection(selection, 'material');
    // };

    // toggleAll = () => {
    //     const selectAll = this.state.selectAll ? false : true;
    //     const selection = [];
    //     if (selectAll) {
    //         const wrappedInstance = this.checkboxTable.getWrappedInstance();
    //         const currentRecords = wrappedInstance.getResolvedState().sortedData;
    //         currentRecords.forEach(item => {
    //             selection.push(item._original.id);
    //         });
    //     }
    //     this.setState({ selectAll, selection });
    //     this.props.setSelection(selection, 'material');

    // };

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    onDeleteClick = (materijalRow) => {
        console.log("row 4", materijalRow, !this.state.deleteFavorite);
        this.setState({ deleteFavorite: !this.state.deleteFavorite, materijalRow });
    }

    deleteItem = () => {
        const { materijalRow } = this.state;
        this.deleteMaterialFavorite(materijalRow.original.id);
        const material = [...this.state.material];
        let materialObj = Object.assign({}, material[materijalRow.index]);
        materialObj[materijalRow.column.id] = !materijalRow.value;
        material[materijalRow.index] = materialObj;
        this.setState({ deleteFavorite: false, material });
        this.reactTable.fireFetchData();
    }

    handlePopUpFavorite = (row) => {
        if (row.value) {
            this.onDeleteClick(row);
        } else {
            this.setState({ materialPopup: true, row, cena: null });
            // this.props.setInitialState('materialObj');
        }
    }

    handleAddFavorite = () => {
        const { cena, row, materialObj } = this.state;
        this.addMaterialToFavorite((row && row.original.id) || materialObj.id, cena);
        //this.props.addMaterialToFavorite((row && row.original.id) || materialObj.id, cena);
        this.setState({ materialPopup: false, cena: null });
        // this.props.onEditFavorit(false);
        this.reactTable.fireFetchData();
    }

    // closePopup = () => {
    //     this.props.onEditFavorit(false);
    //     this.setState({ materialPopup: false, cena: null });
    // }

    handleSearchBarChange = (searchValue) => {
        this.setState({
            searchBarValue: searchValue,
        });
    }

    handleDeleteButtonClick = (id, name) => {
        if(!this.state.deleteMaterialPopUp){
            this.setState({
                deleteMaterialId: id,
                deleteMaterialName: name,
            })
        }  
        this.setState({
            deleteMaterialPopUp: !this.state.deleteMaterialPopUp,
        })
    }

    closeDeleteMaterial = () => {
        this.setState({
            deleteMaterialPopUp: false,
        })
    }

    handleDeleteMaterial = () => {

        const { deleteMaterialId } = this.state;
    
        axios.post(`api/material/delete`, { id: deleteMaterialId })
            .then(response => {
                console.log('Delete successful:', response.data);
                this.setState({
                    deleteMaterialPopUp: false,
                })
                // this.fetchAllMaterialGroups();
                // this.fetchAllMaterialSubGroups();
                // this.fetchAllMaterialUnits();
                this.reactTable.fireFetchData();
            })
            .catch(error => {
                if (error.response.data.code == 23503) {
                    console.error('Delete failed: Material is referenced by other records.');
                    wentWrong('You can\'t delete material that is used in activities!');
                } else {
                    console.error('Delete failed:', error);
                    wentWrong('Something went wrong, please try again!');
                }
            });
    }

    handleChangeGroup = (e) => {
        this.setState({ selectedGroup: e.currentTarget.value });
    }

    handleChangeSubGroup = (e) => {
        this.setState({ selectedSubGroup: e.currentTarget.value });
    }

    handleChangeUnit = (e) => {
        this.setState({ selectedUnit: e.currentTarget.value });
    }

    handleOpenAddMaterial = () => {
        this.setState({ showAddMaterial: true });
    }

    handleCloseAddMaterial = () => {
        this.setState({ showAddMaterial: false });
    }

    handleOpenEditMaterial = () => {
        this.setState({ showEditMaterial: true });
    }

    handleCloseEditMaterial = () => {
        this.setState({ showEditMaterial: false });
    }

    handelAfterAddMaterial = (materialObj) => {

        axios.post('api/material/create', materialObj)
        .then(response => {
            const { id, cena } = response.data.data;
            console.log("id i cena", id, cena);
            if(!materialObj.id){
                axios.post(`api/material/addFavorite`, { id_materijal: id, cena })
                .then(response => {
                    this.fetchAllMaterialGroups();
                    this.fetchAllMaterialSubGroups();
                    this.fetchAllMaterialUnits();
                    this.reactTable.fireFetchData(); 
                })
                .catch(function (error) {
                    wentWrong('Something went wrong, please try again!');
                });
            }else {
                this.setState({ 
                    showEditMaterial: false,
                 });
                this.fetchAllMaterialGroups();
                this.fetchAllMaterialSubGroups();
                this.fetchAllMaterialUnits();
                this.reactTable.fireFetchData(); 
            }
                   
            // dispatch(getMaterialGroup());
            // dispatch(getMaterialSubgroup());
        })
        .catch(function (error) {
            wentWrong('Something went wrong, please try again!');
        });
        this.setState({ 
            showAddMaterial: false,
         });
         const { page, pageSize } = this.reactTable.state;
         if (page !== 0 || pageSize !== this.state.pageSize) {
             this.reactTable.state.page = 0;
         }
        //  this.fetchAllMaterialGroups();
        //         this.fetchAllMaterialSubGroups();
        //         this.fetchAllMaterialUnits();
        //         this.reactTable.fireFetchData(); 
    }

    handelAfterEditMaterial = () => {
        this.setState({ 
            showEditMaterial: false,
         });
        this.fetchAllMaterialGroups();
        this.fetchAllMaterialSubGroups();
        this.fetchAllMaterialUnits();
        this.reactTable.fireFetchData();
    }

    render() {
        // const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll, user, materialPopup, cena } = this.state;
        const { theme, classes } = this.props;
        const columns = [
            {
                Header: i18n.t('Active'),
                accessor: "favorit",
                filterable: false,
                width: 80,
                headerStyle: commonHeaderStyle,
                show: user.id_korisnik_grupa === 10 ? true : false,
                Cell: (row) => {
                    return (
                        <div className={classes.center}>
                            <Tooltip title={row.value ? i18n.t('Remove from active') : i18n.t('Add to active')}>
                                <IconButton
                                    className={classes.starButton}
                                    style={{ color: row.value ? '#f2ce3e' : 'gray', marginBottom: '0.4vh', height: '1.2vh' }}
                                    onClick={() => this.handlePopUpFavorite(row)}>
                                    {
                                        row.value ? <Icons.GradeRounded /> :
                                            <Icons.GradeOutlined />
                                    }
                                </IconButton>
                            </Tooltip>
                        </div >
                    );
                }
            },
            {
                Header: i18n.t('Title'),
                accessor: 'naziv',
                headerStyle: commonHeaderStyle,
            },
            {
                Header: i18n.t('Material group name'),
                accessor: 'materijal_grupa_naziv',
                headerStyle: commonHeaderStyle,
                Filter: ({ onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.materialGroupDropdown}
                        className="multi-select-in-filter"
                        placeholder={i18n.t('Select')}
                        classNamePrefix="select"
                    />
            },
            {
                Header: i18n.t('Material subgroup name'),
                accessor: 'materijal_podgrupa_naziv',
                headerStyle: commonHeaderStyle,
                Filter: ({ onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.materialSubgroupDropdown}
                        className="multi-select-in-filter"
                        placeholder={i18n.t('Select')}
                        classNamePrefix="select"
                    />
            },
            {
                Header: i18n.t('Price'),
                accessor: 'cena',
                width: 80,
                headerStyle: commonHeaderStyle,
            },
            {
                Header: i18n.t('Unit'),
                accessor: 'unit',
                headerStyle: commonHeaderStyle,
                width: 100,
                className: classes.center,
                Filter: ({  onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.unit}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                id: 'organska_proizvodnja',
                Header: i18n.t('Permitted in organic production'),
                width: 200,
                headerStyle: commonHeaderStyle,
                className: classes.center,
                accessor: d => d.organska_proizvodnja,
                Cell: ({ value }) => value === true ? i18n.t('Yes') : i18n.t('No'),
                Filter: ({ onChange }) =>
                    <ForBooleanFilter
                        onChange={(s) => {
                            onChange(s);
                        }}
                    />
            },
            {
                width: 80,
                headerStyle: commonHeaderStyle,
                Header: i18n.t('Edit'),
                getHeaderProps: () => ({
                    onClick: e => e.stopPropagation(),
                    style: {
                        cursor: 'default'
                    }
                }),
                accessor: 'izmena',
                Cell: cell => (
                    <div>
                        {user.id_klijent === this.state.id_klijent_vlasnik &&
                        <Tooltip2 title={i18n.t("Edit")}>
                                    <IconButton aria-label="copy"
                                        onClick={() => {
                                            const { id, cena, id_materijal_grupa, id_materijal_podgrupa, id_unit, naziv } = cell.row._original;
                                            this.setState({ 
                                                materialObjEdit: { id, cena, id_materijal_grupa, id_materijal_podgrupa, id_unit, naziv } 
                                            });
                                            this.handleOpenEditMaterial()
                                        }}
                                    >
                                        <Icons.Edit fontSize="default" />
                                    </IconButton>
                                </Tooltip2>}
                    </div>
                )
            },
            {
                width: 80,
                headerStyle: commonHeaderStyle,
                getHeaderProps: () => ({
                    onClick: e => e.stopPropagation(),
                    style: {
                        cursor: 'default'
                    }
                }),
                Header: i18n.t('Delete'),
                accessor: 'brisanje',
                Cell: cell => (
                    <div>
                        {user.id_klijent === this.state.id_klijent_vlasnik && !cell.row._original.favorit &&
                        <Tooltip2 title={i18n.t("Delete")}>
                                    <IconButton aria-label="Delete"
                                        onClick={() => this.handleDeleteButtonClick(cell.row._original.id, cell.row._original.naziv)}
                                    >
                                        <Icons.DeleteOutline fontSize="default" />
                                    </IconButton>
                                </Tooltip2>}
                    </div>
                )
            },
        ];

        return (
            <div>
                <div className={classes.searchWrap}>
                    <SearchBar
                        className={classes.searchBar}
                        placeholder={i18n.t('Search by name')}
                        cancelOnEscape={true}
                        value={this.state.searchBarValue}
                        onChange={(searchValue) => this.handleSearchBarChange(searchValue)}
                        onCancelSearch={() => this.handleSearchBarChange('')}
                    />
                    {getUser().id_klijent === this.state.id_klijent_vlasnik && 
                    <Button
                        variant="fab"
                        color="primary"
                        aria-label="Add"
                        className={classes.addNewBtn}
                        onClick={this.handleOpenAddMaterial}
                        >
                        <AddIcon />
                        {i18n.t('Add material')}
                    </Button>}
                </div>
                <div className={classes.filtersSection}>
                    <select className={classes.dropDowns} value={this.state.selectedGroup} onChange={this.handleChangeGroup} name="group" id="group">
                        <option value="">{i18n.t('Material group')}</option>
                        {this.state.allGroups && this.state.allGroups.map((status) => (
                            <option key={status.id} value={status.id}>{status.value}</option>
                        ))}
                    </select>
                    <select className={classes.dropDowns} value={this.state.selectedSubGroup} onChange={this.handleChangeSubGroup} name="sub_group" id="sub_group">
                        <option value="">{i18n.t('Material subgroup')}</option>
                        {this.state.allSubGroups && this.state.allSubGroups.map((status) => (
                            <option key={status.id} value={status.id}>{status.value}</option>
                        ))}
                    </select>
                    <select className={classes.dropDowns} value={this.state.selectedUnit} onChange={this.handleChangeUnit} name="unit" id="unit">
                        <option value="">{i18n.t('Unit')}</option>
                        {this.state.allUnits && this.state.allUnits.map((status, i) => (
                            <option key={i} value={status.value}>{status.value}</option>
                        ))}
                    </select>
                </div>
                <div style={{ border: '1px solid #cecece', backgroundColor: '#fff', margin: '0 30px 30px 30px', borderRadius: '3px', boxSizing: 'border-box', overflow: 'hidden', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)', display: 'flex', flexDirection: 'column' }}>
                        <ReactTable ref={r => (this.reactTable = r)}
                            manual
                            paging={true}
                            data={this.state.data}
                            columns={columns}
                            keyField={'id'}
                            pageRangeDisplayed={false}
                            defaultPageSize={this.state.pageSize}
                            pages={this.state.totalPages}
                            showPaginationBottom={true}
                            onFetchData={(state) => this.getData(state)}
                            firtelable={true}
                            previousText={'<'}
                            nextText={'>'}
                            pageText={''}
                            ofText={i18n.t('of')}
                            showPageSizeOptions={false}
                            showPagination={true}
                            rowsText={''}
                            PreviousComponent={(props) => (
                                <button {...props} 
                                className={
                                    classes.paginationButton}
                                >{props.children}</button>
                            )}
                            NextComponent={(props) => (
                                <button {...props} className={
                                    classes.paginationButton}
                                >{props.children}</button>
                            )}
                            getTrProps={() => {
                                    return {
                                        style: {
                                            textAlign: 'center',
                                            paddingLeft: '10px',
                                            height: '50px',
                                            alignItems: 'center',
                                            overflowY: 'hidden',
                                            overflowX: 'hidden'
                                        }
                                    };
                                
                            }}
                            LoadingComponent={() => (
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <PulseLoader loading={this.state.load}/>
                                </div>                         
                            )}
                            onPageChange={() => {
                                this.state.load= true ;
                              }}
                              NoDataComponent={() => (
                                <div style={{ textAlign: 'center', padding: '20px', display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: -300 }}>
                                    {!this.state.load && <span>{i18n.t('No rows found')}</span>}
                                </div>
                            )}
                    />
                </div>
                {this.state.deleteFavorite &&
                    <Delete
                        delete={this.state.deleteFavorite}
                        handleClose={this.onDeleteClick}
                        deleteItem={this.deleteItem}
                        favorite={true} />
                }
                {(materialPopup || this.props.favorit) &&
                    <Dialog
                        open={materialPopup || this.props.favorit}
                        onClose={this.closePopup}
                        disableBackdropClick
                        maxWidth="sm"
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle id="responsive-dialog-title">{i18n.t('Price')}</DialogTitle>
                        <DialogContent style={{ minHeight: '280px' }}>
                            <Grid item >
                                <TextField
                                    label={i18n.t('Price')}
                                    value={cena}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={(event) => this.setState({ cena: event.target.value })}
                                    type="number"
                                />
                            </Grid>


                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={this.handleAddFavorite}
                                variant="contained"
                                color="primary">
                                {i18n.t('Ok')}
                            </Button>
                            <Button
                                onClick={this.closePopup}
                                variant="contained"
                                color="primary">
                                {i18n.t('Cancel')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                }

                {this.state.deleteMaterialPopUp && <Delete delete={this.state.deleteMaterialPopUp} handleClose={this.closeDeleteMaterial} deleteItem={this.handleDeleteMaterial} />}
                {this.state.showAddMaterial && <AddMaterial add={this.state.showAddMaterial} handleClose={this.handleCloseAddMaterial} handleFinishAction={this.handelAfterAddMaterial}/>}
                {this.state.showEditMaterial && <AddMaterial edit={true} editMaterialObject={this.state.materialObjEdit} add={this.state.showEditMaterial} handleFinishAction={this.handelAfterAddMaterial} handleClose={this.handleCloseEditMaterial}/>}
               </div>
        )
    }
};

// function mapStateToProps(state) {
//     return {
//         material: state.materialReducer.material,
//         materialObj: state.materialReducer.materialObj,
//         materialsFetching: state.materialReducer.materialsFetching,
//         materialsPages: state.materialReducer.materialsPages,
//         materialGroupDropdown: state.materialReducer.materialGroupDropdown,
//         groupFetching: state.materialReducer.groupFetching,
//         groupFetchingFailed: state.materialReducer.groupFetchingFailed,
//         materialSubgroupDropdown: state.materialReducer.materialSubgroupDropdown,
//         subgroupFetching: state.materialReducer.subgroupFetching,
//         subgroupFetchingFailed: state.materialReducer.subgroupFetchingFailed,
//         crops: state.cropReducer.crops,
//         cropFetching: state.cropReducer.cropFetching,
//         cropFetchingFailed: state.cropReducer.cropFetchingFailed,
//         unit: state.unitReducer.unit,
//         unitFetching: state.unitReducer.unitFetching,
//         unitFetchingFailed: state.unitReducer.unitFetchingFailed,
//         materialPostingSuccess: state.materialReducer.materialPostingSuccess,
//         deleted: state.materialReducer.deleted,
//         addFavoriteSuccess: state.materialReducer.addFavoriteSuccess,

//     }
// }

// function mapDispatchToProps(dispatch) {
//     return {
//         getMaterialGroup: () => dispatch(getMaterialGroup()),
//         getMaterialSubgroup: () => dispatch(getMaterialSubgroup()),
//         getCrop: () => dispatch(getCrop()),
//         getUnit: () => dispatch(getUnit()),
//         setInitialState: (component) => dispatch(setInitialState(component)),
//         setRowInReducer: (rowInfo, name) => dispatch(setRowInReducer(rowInfo, name)),
//         getMaterial: (state, instance) => dispatch(getMaterial(state, instance)),
        // addMaterialToFavorite: (id_materijal, cena) => dispatch(addMaterialToFavorite(id_materijal, cena)),
//         deleteMaterialFavorite: (id_materijal) => dispatch(deleteMaterialFavorite(id_materijal)),
//     }
// }

// export default withStyles(styles, { withTheme: true })(connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(ViewMaterials));
export default withStyles(styles, { withTheme: true })(connect(

)(ViewMaterials));