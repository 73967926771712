import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n/i18n';
import { Button, IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//  import InputLabel from '@material-ui/core/InputLabel';
import { getMaterialSubgroup, getMaterialGroup, setInitialState, addMaterial } from '../../../actions/resources/MaterialActions';
import { getUnit } from '../../../actions/resources/UnitActions';
import { getCrop } from '../../../actions/resources/CropActions';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
const styles = theme => ({
});
export class AddMaterial extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            materialObj: this.props.editMaterialObject ? this.props.editMaterialObject : this.props.materialObj
        }
    }

    componentDidMount() {
        this.props.getMaterialGroup();
        this.props.getMaterialSubgroup();
        this.props.getCrop();
        this.props.getUnit();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.materialPostingFailed === true) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('materialPostingFailed');
        }
    }

    handleChangeValue = name => event => {
        let obj = Object.assign({}, this.state.materialObj);
        obj[name] = event.target.value;
        this.setState({ materialObj: obj });
        if ([name] == 'id_materijal_grupa') {
            this.props.getMaterialSubgroup(event.target.value);
        }
    };


    handleChangeCheckbox = name => event => {
        let obj = Object.assign({}, this.state.materialObj);
        obj[name] = event.target.checked;
        this.setState({ materialObj: obj });
    };



    addMaterial() {
        if (this.state.materialObj.naziv &&
            this.state.materialObj.id_unit
            && this.state.materialObj.id_materijal_grupa
            && this.state.materialObj.id_materijal_podgrupa) {
            // this.props.addMaterial(
            //     this.state.materialObj
            // )
            this.props.handleFinishAction(this.state.materialObj);
            
        } else {
            toast.error(i18n.t('Please enter all required fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
        }
    }


    render() {
        const { theme } = this.props;
        let group = [];
        if (this.props.materialGroupDropdown && !this.props.groupFetching) {
            group = this.props.materialGroupDropdown.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let subgroup = [];
        if (this.props.materialSubgroupDropdown && !this.props.subgroupFetching) {
            subgroup = this.props.materialSubgroupDropdown.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let crop = [];
        if (this.props.crops && !this.props.cropFetching) {
            crop = this.props.crops.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let unit = [];
        if (this.props.unit && !this.props.unitFetching) {
            unit = this.props.unit.map((s, i) => {
                return <option key={s.value} value={s.value}>{s.label}</option>

            });
        }
        return (
            <div style={{ padding: 20 }}>

                {/* <Paper className="roundedBoxMaterial" zdepth={4}> */}
                <Dialog
                    open={this.props.add}
                    onClose={this.props.handleClose}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle disableTypography={true} style={{ background: theme.palette.secondary.dark }} className="responsive-dialog-title">{this.props.edit ? i18n.t('Edit material') : i18n.t('Add material')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <form id="add-material">
                            <Grid container justify="space-between" alignContent="space-between" spacing={16}>
                                {/* <Grid item xs={12} sm={6}>
                                    <TextField
                                        label={i18n.t('Code')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={this.state.materialObj.sifra}
                                        onChange={this.handleChangeValue('sifra')}
                                        type="number"
                                    />
                                </Grid> */}
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label={i18n.t('Title')}
                                        required
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={this.state.materialObj.naziv}
                                        onChange={this.handleChangeValue('naziv')} />
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <TextField
                                        label={i18n.t('Price')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={this.state.materialObj.cena}
                                        onChange={this.handleChangeValue('cena')}
                                        type="number"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <TextField
                                        select
                                        label={i18n.t('Material group name')}
                                        value={this.state.materialObj.id_materijal_grupa}
                                        onChange={this.handleChangeValue('id_materijal_grupa')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        required
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                        <option value='' />
                                        {group}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <TextField
                                        select
                                        label={i18n.t('Material subgroup name')}
                                        disabled={!this.state.materialObj.id_materijal_grupa}
                                        value={this.state.materialObj.id_materijal_podgrupa}
                                        onChange={this.handleChangeValue('id_materijal_podgrupa')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        required
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                        <option value='' />
                                        {subgroup}
                                    </TextField>
                                </Grid>
                                {/* <Grid item xs={12} sm={6} >
                                    <TextField
                                        select
                                        required
                                        label={i18n.t('Crop type')}
                                        value={this.state.materialObj.id_kultura}
                                        onChange={this.handleChangeValue('id_kultura')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                        <option value='' />
                                        {crop}
                                    </TextField>
                                </Grid> */}
                                <Grid item xs={12} sm={12} >
                                    <TextField
                                        select
                                        required
                                        label={i18n.t('Unit')}
                                        value={this.state.materialObj.id_unit}
                                        onChange={this.handleChangeValue('id_unit')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                        <option value='' />
                                        {unit}
                                    </TextField>
                                </Grid>
                                {/* <Grid item xs={12} sm={6} >
                                    <FormControlLabel
                                        style={{ marginTop: '10px', marginBottom: '10px' }}
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={this.state.materialObj.organska_proizvodnja}
                                                onChange={this.handleChangeCheckbox('organska_proizvodnja')}
                                                value="organska_proizvodnja"
                                            />
                                        }
                                        label={i18n.t('Permitted in organic production')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <FormControlLabel
                                        style={{ marginTop: '10px', marginBottom: '10px' }}
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={this.state.materialObj.stari_podatak}
                                                onChange={this.handleChangeCheckbox('stari_podatak')}
                                                value="stari_podatak"
                                            />
                                        }
                                        label={i18n.t('Old data')}
                                    />
                                </Grid> */}
                                <Grid item xs>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => this.addMaterial()}
                                    >
                                        {this.props.edit ? i18n.t('Save') : i18n.t('Add')}
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
                {/* </Paper> */}
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        materialGroupDropdown: state.materialReducer.materialGroupDropdown,
        groupFetching: state.materialReducer.groupFetching,
        groupFetchingFailed: state.materialReducer.groupFetchingFailed,
        materialSubgroupDropdown: state.materialReducer.materialSubgroupDropdown,
        subgroupFetching: state.materialReducer.subgroupFetching,
        subgroupFetchingFailed: state.materialReducer.subgroupFetchingFailed,
        crops: state.cropReducer.crops,
        cropFetching: state.cropReducer.cropFetching,
        cropFetchingFailed: state.cropReducer.cropFetchingFailed,
        unit: state.unitReducer.unit,
        unitFetching: state.unitReducer.unitFetching,
        unitFetchingFailed: state.unitReducer.unitFetchingFailed,
        materialPosting: state.materialReducer.materialPosting,
        materialPostingFailed: state.materialReducer.materialPostingFailed,
        materialObj: state.materialReducer.materialObj
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getMaterialGroup: () => dispatch(getMaterialGroup()),
        getMaterialSubgroup: (id) => dispatch(getMaterialSubgroup(id)),
        getCrop: () => dispatch(getCrop()),
        getUnit: () => dispatch(getUnit()),
        setInitialState: (component) => dispatch(setInitialState(component)),
        addMaterial: (materialObj) => dispatch(addMaterial(materialObj)),
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(AddMaterial));