import React from 'react';
import i18n from '../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { wentWrong } from "../../../utils/ToastConfig";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from '../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';

export default class AddWorkerUnits extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            worker_unit: null,
        }
    }

    componentDidMount() {
        if (this.props.edit === true) {
            this.setState({
                worker_unit: this.props.data.id_jmr
            })
        }
    }

    getByURL(url, obj, value, name) {
        value = value || '';
        name = name || '';
        if (value != null) {
            axios.get(`api/${url}`, { params: { [name]: value } })
                .then(res => this.setState({ [obj]: res.data.data }))
                .catch(error => wentWrong('Something went wrong, please try again!'));
        }
    }

    addWorkerUnit() {
        if (
            this.state.worker_unit
        ) {
            let data = {
                id: this.props.data.id, // da prepozna za edit na backendu
                naziv: this.state.worker_unit,
            }
            axios.post('api/worker_unit/create', { data: data })
                .then(res => {
                    this.props.handleClose();
                })
                .catch(error => {
                    wentWrong('Something went wrong, please try again!');
                    console.log(`Error: ${error}`);
                })
        }
        else wentWrong('Please enter all required fields!');
    }

    render() {
        return (
            <div style={{ padding: 20 }}>
                <Dialog open={this.props.add}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle disableTypography={true} style={{ background: '#04764e' }} className="responsive-dialog-title">{this.props.edit ? i18n.t('Edit Worker Unit') : i18n.t('Add Worker Unit')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <form id="add-layer">
                            <Grid container justify="space-between" alignContent="space-between" >

                                <Grid xs={12}>
                                    <TextField required
                                        label={i18n.t('Worker unit')}
                                        value={this.state.worker_unit}
                                        InputLabelProps={this.state.worker_unit ? { shrink: true } : null}
                                        style={{ width: '532px', marginBottom: '10px', marginLeft: '10px', marginTop: '10px' }}
                                        onChange={(e) => { this.setState({ worker_unit: e.target.value }); }} >
                                    </TextField>
                                </Grid>

                                <Grid item xs={12}>
                                    <Button style={{ alignSelf: 'center', backgroundColor: '#04764e', marginLeft: '10px', color: 'white', marginTop: '20px' }}
                                        onClick={() => this.addWorkerUnit()}
                                        disabled={this.props.layerPosting} >
                                        {this.props.edit ? i18n.t('Save') : i18n.t('Add')}
                                    </Button>
                                </Grid>

                                <Grid item xs={3}>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
};