import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import i18n from '../../../i18n/i18n';
import Select from 'react-select';
import { Fab } from '@material-ui/core';
import { wentWrong } from "../../../utils/ToastConfig";
import axios from '../../../utils/AxiosWrapper';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import { Delete } from '../../../utils/Delete';
import AddWorkers from '../workers/AddWorkers';
import WentWrong from './../../../utils/ToastConfig';
const styles = theme => ({ primaryMain: { backgroundColor: theme.palette.primary.main } });
const CheckboxTable = checkboxHOC(ReactTable);;

export default class ViewWorkers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            workplaceType: [], groupType: [], sectorType: [],
            showFilters: false, loading: false,
            page: 0, pageSize: 25, sorted: null, filtered: null,
            selection: [], selectAll: false, selectedRow: [],
            totalPages: 0, totalWorkers: 0,
            add: false, edit: false, delete: false,
            data: [],
        };
        this.toggleSelection = this.toggleSelection.bind(this);
    }

    componentDidMount() {
        this.getByURL('workplace/drop_down', 'workplaceType');
        // this.getByURL('worker/filter_worker_group', 'groupType');
        this.getByURL('worker/filter_sector', 'sectorType');
    }

    getData(url, state, instance) {
        this.setState({ loading: true, data: [] });
        axios.get(`api/${url}`, {
            params: { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: state.filtered }
        })
            .then(res => {
                this.setState({
                    data: res.data.data,
                    totalPages: res.data.total_pages,
                    totalWorkers: res.data.total,
                    loading: false
                });
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 1 (getData): ', error);
            })
    }

    getByURL(url, obj) {
        axios.get(`api/${url}`)
            .then(res => this.setState({ [obj]: res.data.data }))
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 2 (getByURL): ', error, ', URL: ', url);
            })
    }

    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true, selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => { selection.push(item._original.id); });
        }
        this.setState({ selectAll, selection });
    };

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection], selectedRow = null;
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) selection = [...selection.slice(0, keyIndex), ...selection.slice(keyIndex + 1)];
        else selection.push(key);
        if (selection.length == 1) selectedRow = this.state.data.filter((el) => el.id == selection)[0];
        this.setState({ selection, selectedRow });
    };

    handleClick = () => {
        if (this.state.edit == true && this.state.add == false) this.setState({ edit: false, selectedRow: [] });
        else if (this.state.selection.length > 0 && this.state.add == false && this.state.edit == false) this.setState({ selectedRow: [] });
        this.setState({ add: !this.state.add }, () => {
            if (this.state.add === false) this.getData('worker/read', this.state);
            if (this.state.add == true && this.state.edit == false) this.setState({ selection: [], selectAll: false, });
        });
    };

    deleteItem = () => {
        axios.get('api/worker_activities/check_worker', { params: { selection: this.state.selection } })
            .then(res => {
                if (res.data.total > 0) wentWrong(i18n.t('There are worker activities tied to this worker'));
                else if (res.data.total === 0) {


                    axios.post(`api/worker/delete`, { selection: this.state.selection })
                        .then(res => {
                            this.getData('worker/read', this.state);
                            this.setState({ delete: false, selection: [] });
                        })
                        .catch(err => {
                            this.setState({ delete: false });
                            console.log('Error: ', err);
                        })
                }
            })
            .catch(err => { console.log('Error: ', err); })
    }

    isSelected = key => { return this.state.selection.includes(key); };
    onSearchClick = () => this.setState({ showFilters: !this.state.showFilters });
    onEditClick() { this.setState({ add: !this.state.add, edit: true }); }
    onTableClick = () => this.props.history.push('/workers');
    onDeleteClick = () => { this.setState({ delete: !this.state.delete }); }

    render() {
        let workplaceType = this.state.workplaceType.map((item) => { return { label: item.naziv, value: item.id } });
        let groupType = this.state.groupType.map((item) => { return { label: item.naziv, value: item.id } });
        let sectorType = this.state.sectorType.map((item) => { return { label: item.naziv, value: item.id } });

        const columns = [
            { Header: i18n.t('First name'), accessor: 'ime' },
            { Header: i18n.t('Last name'), accessor: 'prezime' },
            { Header: i18n.t('Password'), accessor: 'sifra' },
            { Header: i18n.t('Workplace'), accessor: 'naziv_radno_mesto', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={workplaceType} /> },
            { Header: i18n.t('Category'), accessor: 'kategorija' },
            // { Header: i18n.t('Workgroup'), accessor: 'naziv_radnik_grupa', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={groupType} /> },
            // { Header: i18n.t('Sector'), accessor: 'naziv_sektor', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={sectorType} /> },
            { id: 'polj_radovi', Header: i18n.t('Agricultural works'), accessor: d => d.polj_radovi, Cell: ({ value }) => value != 0 ? i18n.t('Yes') : i18n.t('No') },
            // { Header: i18n.t('Price'), accessor: 'cena' },
        ];
        const url = 'worker/read';

        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll } = this.state;
        const checkboxProps = {
            selectAll, isSelected, toggleSelection, toggleAll, selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return { style: { backgroundColor: selected ? "#ACDAF1" : "inherit", alignItems: 'flex-end' } };
                }
                return {};
            },
            getTheadFilterProps: () => { return { style: { backgroundColor: '#58B4E5' } }; },
            getTheadTrProps: () => { return { style: { backgroundColor: '#04764e', color: '#FFFFFF' } }; },
        };

        return (
            <div className="page">
                <div className="table-header">
                    <Fab onClick={() => this.onSearchClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button">
                        <span className="icon-search headerIcon" />
                    </Fab>
                    <Fab onClick={() => this.handleClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button">
                        <span className="icon-Plus headerIcon" />
                    </Fab>
                    {this.state.selection.length === 1 ?
                        <Fab onClick={() => this.onEditClick()}
                            color="secondary"
                            aria-label="edit"
                            className="action-button">
                            <span className="icon-Izmeni1 headerIcon" />
                        </Fab>
                        : null
                    }
                    {this.state.selection.length > 0 ?
                        <Fab onClick={() => this.onDeleteClick()}
                            color="secondary"
                            aria-label="add"
                            className="action-button">
                            <span className="icon-Kanta headerIcon" />
                        </Fab>
                        : null
                    }
                </div>
                <CheckboxTable ref={r => (this.checkboxTable = r)}
                    manual
                    data={this.state.data}
                    paging={true}
                    columns={columns}
                    keyField={'id'}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    pages={this.state.totalPages}
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => { this.getData(url, state, instance); }}
                    loading={this.state.loading}
                    className="-striped -highlight"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#58B4E5',
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#58B4E5',
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                />
                {this.state.add && <AddWorkers add={this.state.add} edit={this.state.edit} handleClose={this.handleClick} data={this.state.selectedRow} />}
                {this.state.delete && <Delete delete={this.state.delete} handleClose={this.onDeleteClick} deleteItem={this.deleteItem} />}
            </div>
        );
    }
}

function FilterSelect(props) {
    return <Select
        isMulti
        name="naziv"
        onChange={value => { props.onChange(value) }}
        closeMenuOnSelect={false}
        options={props.type}
        className="multi-select-in-filter"
        classNamePrefix="select"
        placeholder={i18n.t('Select')}
    />
}