import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import i18n from '../../../i18n/i18n';
import Select from 'react-select';
import { Fab } from '@material-ui/core';
import { wentWrong } from "../../../utils/ToastConfig";
import axios from '../../../utils/AxiosWrapper';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import { Delete } from '../../../utils/Delete';
import ReactSelect from './../../../utils/ReactSelect';
import { Button, IconButton } from '@material-ui/core';
import { Export } from '../../../utils/Export';
import AddField from './AddField';
import MeteoDialog from './../../meteoblue/MeteoDialog';
// ---------------------------------------
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import SearchBar from 'material-ui-search-bar';
import AddIcon from '@material-ui/icons/Add';
import * as Icons from '@material-ui/icons'
import { Tooltip as Tooltip2 } from '@material-ui/core';
import ReactPaginate from 'react-paginate';


var FileSaver = require('file-saver');
var XLSX = require('xlsx');


const styles = theme => ({
    primaryMain: {
        backgroundColor: theme.palette.primary.main,
    },

    // --------------------------------

    searchWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px 30px 10px 30px',
    },
    searchBar: {
        width: '40%',
        height: '40px !important',
        border: '1px solid #979797',
        maxWidth: '421px',
    },
    addNewBtn: {
        width: 'fit-content',
        height: 40,
        padding: '0 15px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 0,
        backgroundColor: '#5EB3E4',
        '&:hover': {
            backgroundColor: '#4FA2CD',
        },
    },
    filtersSection: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
        paddingLeft: '30px',
        paddingTop: '20px',
    },
    dropDowns: {
        backgroundColor: '#F4F6F7',
        color: '#333333',
        fontSize: '14px',
        border: 'none',
        borderBottom: '1px solid #333333',
        marginRight: '20px',
        cursor: 'pointer',
        '&:focus': {
            outline: 'none',
        },
    },
    numberInput: {
        fontSize: '14px',
        marginRight: '20px',
        maxWidth: '130px',
        height: '19px',
        '&:focus': {
            outline: 'none',
        },
    },
    resetButton: {
        height: '25px',
        border: '2px solid #9b9658',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    tableHeader: {
        height: '40px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#1F764E',
        borderTopRightRadius: '3px',
        borderTopLeftRadius: '3px',
    },
    tableHeaderTitle: {
        fontSize: '16px',
        color: 'white',
    },
    paginationContainer: {
        marginTop: 'auto',
        marginBottom: 0,
        listStyle: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '16px',
        userSelect: 'none',
        backgroundColor: 'white',
        padding: 0,
        color: '#7d7a7a',
        '& > li': {
            margin: '20px',
        },
        '& > .previous': {
            fontSize: '20px',
            padding: '5px',
            fontWeight: 'bold',
            width: '20px',
            height: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '& > .next': {
            fontSize: '20px',
            padding: '5px',
            fontWeight: 'bold',
            width: '20px',
            height: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '& > li > a': {
            cursor: 'pointer',
            color: '#7d7a7a',
        }
    },
    active: {
        border: '2px solid #7d7a7a',
        borderRadius: '50%',
        padding: '5px',
        width: '20px',
        height: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    exportButton: {
        width: '30px !important',
        height: '30px !important',
        minHeight: '30px !important',
        fontSize: '24px !important',
        marginLeft: 'auto !important',
        marginRight: '30px',
    },
});

const CheckboxTable = checkboxHOC(ReactTable);;

export class ViewField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            layerType: [], cropGroupType: [], localGovType: [], productionType: [], cropSubgroupType: [], cropType: [], mainFieldType: [], sectorType: [],
            showFilters: false, loading: true,
            page: 0, pageSize: 25, sorted: null, filtered: null,
            selection: [], selectAll: false, selectedRow: [],
            totalPages: 0, totalFields: 0,
            add: false, edit: false, delete: false,
            data: [], user_season: null,
            selectedCropGroup: [], docTitle: null, user_layer: null,
            meteo: false,
            // ---------------------------------------
            allFields: [],
            filteredFields: [],
            searchBarValue: "",
            selectedLandArea: "",
            selectedParcel: "",
            selectedCrop: "",
            selectedSector: "",
            isFirstRender: true,
            addNewField: false,
            deleteItemId: null,
            deleteWindow: false,
            editWindow: false,
            editItem: null,
            meteoWindow: false,

            numberOfAllFields: 0,
            numberOfFilteredFields: 0,
            totalAreaAllFields: 0,
            totalAreaFilteredFields: 0,


            currentPage: 0, // index based
            pageCount: 1,
            itemsPerPage: 7,

            exportWindow: false,
            docTitle: null,

        };
        this.toggleSelection = this.toggleSelection.bind(this);
    }

    componentDidMount() {
        this.fetchAllFields();

        this.setupPageCount();

        // ----------------------------------------------
        localStorage.removeItem('row');

        axios.get('api/season/drop_down')
            .then(res => {
                let seasons = res.data.data.map(item => item.id);
                axios.get('api/layer/layers_by_season', { params: { seasons } })
                    .then(res => {
                        this.setState({ layerType: res.data.data }, () => {
                            axios.get('api/season/activity_season')
                                .then(res => {
                                    this.setState({ user_season: res.data.data[0].id_default_sezona }, () => {
                                        axios.get('api/layer/layer_by_season', { params: { season: this.state.user_season } })
                                            .then(res => {
                                                this.setState({ user_layer: res.data.data[0].id, loading: false }, () => {
                                                    // this.checkboxTable.wrappedInstance.fireFetchData();
                                                });
                                            })
                                            .catch(err => { console.log(err) })
                                    })
                                })
                                .catch(err => { console.log(err) })
                        });
                    })
                    .catch(err => { console.log(err); })
            })
            .catch(err => { console.log(err); })
    }

    // --------------------------------------------------
    componentDidUpdate(prevProps, prevState) {
        if (prevState.searchBarValue !== this.state.searchBarValue ||
            prevState.selectedLandArea !== this.state.selectedLandArea ||
            prevState.selectedParcel !== this.state.selectedParcel ||
            prevState.selectedCrop !== this.state.selectedCrop ||
            prevState.selectedSector !== this.state.selectedSector) {
            if (!this.state.isFirstRender) this.setState({ currentPage: 0 });
            this.updateFilteredFields();
            this.saveFiltersInSessionStorage();
        }

        if (prevState.filteredFields !== this.state.filteredFields) {
            this.setupPageCount();
        }
    }

    fetchAllFields() {
        axios.get(`api/field/read_all_by_client`)
            .then(res => this.setState({ allFields: res.data.data },
                () => {
                    this.updateFiltersFromSessionStorage();
                    this.updateFilteredFields();
                    this.setState({ numberOfAllFields: this.state.allFields.length })
                    this.calculateTotalArea();
                    console.log("allFields : ", this.state.allFields)
                }))
            .catch(error => {
                wentWrong(i18n.t('Something went wrong'));
                console.log('Error 1 (getData): ', error);
            })
    }

    updateFilteredFields() {
        this.setState({
            filteredFields: this.state.allFields
                .filter(field => {
                    if (this.state.searchBarValue === "") return field;
                    if (!field.naziv) return;
                    if (field.naziv.toLowerCase().includes(this.state.searchBarValue.toLowerCase())) return field;
                })
                .filter(field => {
                    if (this.state.selectedLandArea === "") return field;
                    if (!field.povrsina) return;
                    if (field.povrsina.includes(this.state.selectedLandArea)) return field;
                })
                .filter(parcel => {
                    if (this.state.selectedParcel === "") return parcel;
                    if (!parcel.parcela_broj) return;
                    if (parcel.parcela_broj.toLowerCase().includes(this.state.selectedParcel.toLowerCase())) return parcel;
                })
                .filter(crop => {
                    if (this.state.selectedCrop === "") return crop;
                    if (!crop.kultura) return;
                    if (crop.kultura.toLowerCase().includes(this.state.selectedCrop.toLowerCase())) return crop;
                })
                .filter(sector => {
                    if (this.state.selectedSector === "") return sector;
                    if (!sector.sektor_naziv) return;
                    if (sector.sektor_naziv.toLowerCase().includes(this.state.selectedSector.toLowerCase())) return sector;
                })
        }, () => {
            this.setupPageCount();
            this.setState({ numberOfFilteredFields: this.state.filteredFields.length })
            this.calculateFilteredArea();
        });
    }

    calculateTotalArea() {
        let totalArea = 0
        this.state.allFields.forEach(element => { totalArea += parseFloat(element.povrsina) });
        this.setState({ totalAreaAllFields: totalArea })
    }

    calculateFilteredArea() {
        let totalArea = 0
        this.state.filteredFields.forEach(element => { totalArea += parseFloat(element.povrsina) });
        this.setState({ totalAreaFilteredFields: totalArea })
    }

    handleSearchBarChange = (searchValue) => {
        this.setState({
            searchBarValue: searchValue,
        });
    }

    handlePageChange = (pageNum) => {
        this.setState({
            currentPage: pageNum.selected,
        })

        sessionStorage.setItem('ViewFieldPageNumber', pageNum.selected);
    }

    setupPageCount = () => {
        const pageCount = Math.ceil(this.state.filteredFields.length / this.state.itemsPerPage);
        this.setState({ pageCount: pageCount }, () => {
            if (this.state.currentPage >= this.state.pageCount && this.state.pageCount > 0) {
                this.setState({ currentPage: this.state.pageCount - 1 });
            }
        });
    }

    handleSelectedLandArea = (e) => {
        this.setState({ selectedLandArea: e.currentTarget.value });
    }

    handleSelectedParcel = (e) => {
        this.setState({ selectedParcel: e.currentTarget.value });
    }

    handleSelectedCrop = (e) => {
        this.setState({ selectedCrop: e.currentTarget.value });
    }

    handleSelectedSector = (e) => {
        this.setState({ selectedSector: e.currentTarget.value });
    }

    resetMyFilters = () => {
        this.setState({
            searchBarValue: "",
            selectedLandArea: "",
            selectedParcel: "",
            selectedCrop: "",
            selectedSector: "",
            currentPage: 0,
        });
    }

    saveFiltersInSessionStorage = () => {
        const viewFieldFilterValues = JSON.stringify({
            searchBarValue: this.state.searchBarValue,
            selectedLandArea: this.state.selectedLandArea,
            selectedParcel: this.state.selectedParcel,
            selectedCrop: this.state.selectedCrop,
            selectedSector: this.state.selectedSector,

        })

        sessionStorage.setItem('viewFieldFilterValues', viewFieldFilterValues);

    }

    updateFiltersFromSessionStorage = () => {
        const storedVlues = sessionStorage.getItem('viewFieldFilterValues');

        if (storedVlues) {
            const parsedFilterValues = JSON.parse(storedVlues);

            this.setState({
                searchBarValue: parsedFilterValues.searchBarValue,
                selectedLandArea: parsedFilterValues.selectedLandArea,
                selectedParcel: parsedFilterValues.selectedParcel,
                selectedCrop: parsedFilterValues.selectedCrop,
                selectedSector: parsedFilterValues.selectedSector,
            }, () => {
                this.updatePageNumberFromSessionStorage();
                this.setupPageCount();
            });
        }


    }

    updatePageNumberFromSessionStorage = () => {
        if (this.state.isFirstRender) {
            const storedNumber = sessionStorage.getItem('ViewFieldPageNumber');

            if (storedNumber) {
                this.setState({ currentPage: Number(storedNumber) });
            } else {
                this.setState({ currentPage: 0 });
            }
        }

        this.setState({ isFirstRender: false });
    }

    handleAddNewField = () => {
        this.setState({ addNewField: true });
    }

    handleCloseAddNewField = () => {
        this.setState({ addNewField: false });
        this.fetchAllFields();
    }

    handleCloseDeleteWindow = () => {
        this.setState({ deleteWindow: false });
        this.fetchAllFields();
    }

    handleDeleteItem = () => {
        axios.post(`api/field/delete`, { selection: [this.state.deleteItemId] })
            .then(res => {
                this.setState({ deleteItemId: null, deleteWindow: false });
                this.fetchAllFields();
            })
            .catch(err => {
                this.setState({ deleteWindow: false });
                wentWrong(i18n.t('Something went wrong, please try again!'))
            })
    }

    handleCloseEditWindow = () => {
        this.setState({ editWindow: false });
        this.fetchAllFields();
    }

    handleMapClick = (id, naziv, sezona, labelSezona) => {
        let row = { id: id, naziv: naziv, id_sezona: sezona, label_sezona: labelSezona };
        localStorage.setItem('row', JSON.stringify(row));
        this.props.history.push('/maps');
    }

    handleForecastClick = (id, naziv) => {
        let row = { id: id, naziv: naziv };
        this.setState({ row }, () => {
            this.setState({ meteoWindow: true })
        });
    };

    handleMeteoWindowClick = () => { this.setState({ meteoWindow: !this.state.meteoWindow }) };

    handleCloseExportWindow = () => {
        this.setState({ exportWindow: false })
    }

    async exportDocument(title, data) {
        let newData = data.map(item => {
            return {
                Naziv: item.naziv ? item.naziv : "",
                Površina: item.povrsina ? `${item.povrsina} (ha)` : "",
                Katastarska_parcela: item.parcela_broj ? item.parcela_broj : "",
                Kultura: item.kultura ? item.kultura : "",
                Sorta_Hibrid: item.sorte.map(sorta => `${sorta.naziv} ${(sorta.naziv && sorta.povrsina) ? '-' : ''} ${sorta.povrsina ? `${sorta.povrsina} (ha)` : ''}`).join('<br>'),
                Kultura: item.kultura ? item.kultura : "",
                Kultura_grupa: item.kultura_grupa ? item.kultura_grupa : "",
                Kultura_podgrupa: item.kultura_podgrupa ? item.kultura_podgrupa : "",
                Vrsta_proizvodnje: item.vrsta_proizvodnje ? item.vrsta_proizvodnje : "",

            }
        });

        try {
            const columns = Object.keys(newData[0]);
            // let htmlTable = '<table><thead><tr>';
            // columns.forEach(column => {
            //     htmlTable += `<th style="border: 1px solid black;">${column}</th>`;
            // });
            // htmlTable += '</tr></thead><tbody>';
            // newData.forEach(obj => {
            //     htmlTable += '<tr>';
            //     columns.forEach(col => {
            //         htmlTable += `<td style="border: 1px solid black;">${obj[col]}</td>`;
            //     });
            //     htmlTable += '</tr>';
            // });
            // htmlTable += '</tbody></table>';
            // const htmlString = '<html><head><meta charset="UTF-8"></head><body>' + htmlTable + '</body></html>';
            // const blob = new Blob([htmlString], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            let htmlTable = `
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
                <meta name="title" content="${title || 'file'}">
                <meta name="author" content="Your Name">
                <meta name="description" content="Description of the data">
                <title>${title || 'file'}</title>
                <style>
                    table {
                        border-collapse: collapse;
                        width: 100%;
                    }
                    th, td {
                        border: 1px solid black;
                        padding: 8px;
                        text-align: left;
                    }
                    th {
                        background-color: #f2f2f2;
                    }
                </style>
            </head>
            <body>
                <table>
                    <thead>
                        <tr>
                            ${columns.map(column => `<th>${column}</th>`).join('')}
                        </tr>
                    </thead>
                    <tbody>
                        ${newData.map(row => `
                            <tr>
                                ${columns.map(column => `<td>${row[column]}</td>`).join('')}
                            </tr>
                        `).join('')}
                    </tbody>
                </table>
            </body>
            </html>
        `;

            // Convert HTML string to Blob
            const blob = new Blob([htmlTable], { type: 'text/html' });

            FileSaver.saveAs(blob, `${title || 'file'}.xlsx`);
        } catch (err) {
            wentWrong(i18n.t('Something went wrong'))
            console.log("BLOB ERROR : ", err)
        }




    };

    exportFunction = () => {
        this.exportDocument(this.state.docTitle, this.state.filteredFields);
        this.setState({ exportWindow: false });
    }
    // --------------------------------------------------

    getData(url, state, instance) {
        this.setState({ loading: true, data: [] });
        axios.get(`api/${url}`, {
            params: { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: state.filtered }
        })
            .then(res => {
                this.setState({
                    data: res.data.data,
                    totalPages: res.data.total_pages,
                    totalFields: res.data.total,
                }, () => {
                    if (this.state.user_layer !== null) this.setState({ loading: false })
                });
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 1 (getData): ', error);
            })
    }

    getByURL(url, obj) {
        axios.get(`api/${url}`)
            .then(res => this.setState({ [obj]: res.data.data }))
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 2 (getByURL): ', error, ', URL: ', url);
            })
    }

    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true, selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => { selection.push(item._original.id); });
        }
        this.setState({ selectAll, selection });
    };

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection], selectedRow = null;
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) selection = [...selection.slice(0, keyIndex), ...selection.slice(keyIndex + 1)];
        else selection.push(key);
        if (selection.length == 1) selectedRow = this.state.data.filter((el) => el.id == selection)[0];
        this.setState({ selection, selectedRow });
    };

    handleClick = () => {
        if (this.state.edit == true && this.state.add == false) this.setState({ edit: false, selectedRow: [] });
        else if (this.state.selection.length > 0 && this.state.add == false && this.state.edit == false) this.setState({ selectedRow: [] });
        this.setState({ add: !this.state.add }, () => {
            if (this.state.add === false) this.checkboxTable.wrappedInstance.fireFetchData();
            if (this.state.add == true && this.state.edit == false) this.setState({ selection: [], selectAll: false, });
        });
    };

    handleClickArchived = () => {
        this.setState({ selection: [], selectAll: false, })
    };

    handleMeteoClick = () => { this.setState({ meteo: !this.state.meteo }) };

    deleteItem = () => {
        axios.post(`api/field/delete`, { selection: this.state.selection })
            .then(res => {
                this.checkboxTable.wrappedInstance.fireFetchData();
                this.setState({ selection: [], delete: false });
            })
            .catch(err => {
                this.setState({ delete: false });
                wentWrong(i18n.t('Something went wrong, please try again!'))
            })
    }

    isSelected = key => { return this.state.selection.includes(key); };
    onSearchClick = () => this.setState({ showFilters: !this.state.showFilters });
    onEditClick() { this.setState({ add: !this.state.add, edit: true }); };
    onTableClick = () => this.props.history.push('/field');
    onDeleteClick = () => { this.setState({ delete: !this.state.delete }); };
    onExportClick = () => { this.setState({ export: !this.state.export }); };
    onMapClick = () => this.props.history.push('/maps');

    handleCloseExportWindow = () => {
        this.setState({ exportWindow: false })
    }

    async exportDocument(title, data) {

        let newData = data.map(item => {
            return {
                Naziv: item.naziv,
                Površina: item.povrsina,
                Katastarsk_parcela: item.parcela_broj,
                Kultura: item.kultura,
                Sorta_Hibrid: item.sorte.map(sorta => sorta.naziv).join('\n'),
                Kultura_grupa: item.kultura_grupa,
                Podgrupa_kultura: item.kultura_podgrupa,
                Ima_radnih_naloga: item.has_activity === "0" ? "Ne" : "Da",

                // Table: item.table.map(tabla => tabla.tabla.naziv).join('\n'),
            }
        });

        try {
            function s2ab(s) {
                const buf = new ArrayBuffer(s.length);
                const view = new Uint8Array(buf);
                for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            const ws = XLSX.utils.json_to_sheet(newData);

            ws['!cols'] = [
                { wpx: 100 },
                { wpx: 200 },
                { wpx: 200 },
                { wpx: 120 },
                { wpx: 120 },
                { wpx: 100 },
                { wpx: 150 },
                { wpx: 80 },
                { wpx: 180 },
                { wpx: 120 },
                { wpx: 120 },
                { wpx: 120 },
                { wpx: 120 },
                { wpx: 120 }
            ]
            ws['!rows'] = [{ hpx: 30 }];

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

            const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
            const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
            FileSaver.saveAs(blob, `${title || 'file'}.xlsx`);

        } catch (err) {
            wentWrong(i18n.t('Something went wrong'))
            console.log("BLOB ERROR : ", err)
        }

    };

    exportFunction = () => {
        this.exportDocument(this.state.docTitle, this.state.filteredFields);
        this.setState({ exportWindow: false });
    }

    handleButtonClick = (cell) => {
        let row = { id: cell._original.id, naziv: cell.naziv };
        // localStorage.setItem('row', JSON.stringify(row));
        // this.onMapClick();
    };

    handleForecast = (cell) => {
        let row = { id: cell._original.id, naziv: cell.naziv };
        this.setState({ row, meteo: true });
    };

    render() {
        let layerType = this.state.layerType.map((item) => { return { label: item.naziv, value: item.id } });
        let cropGroupType = this.state.cropGroupType.map((item) => { return { label: item.naziv, value: item.id } });
        let cropSubgroupType = this.state.cropSubgroupType.map((item) => { return { label: item.naziv, value: item.id } });
        let cropType = this.state.cropType.map((item) => { return { label: item.naziv, value: item.id } });
        // let localGovType = this.state.localGovType.map((item) => { return { label: item.naziv, value: item.id } });
        let productionType = this.state.productionType.map((item) => { return { label: item.naziv, value: item.id } });
        let mainFieldType = this.state.mainFieldType.map((item) => { return { label: item.naziv, value: item.id } });
        let sectorType = this.state.sectorType.map((item) => { return { label: item.naziv, value: item.id } });
        let layers = layerType.map(item => item.value);

        const columns = [
            { Header: i18n.t('Title'), accessor: 'naziv', width: 100 },
            { Header: i18n.t('Land area'), accessor: 'povrsina', width: 80, Cell: ({ value }) => value && parseFloat(value).toFixed(2) },
            { Header: i18n.t('Parcel'), accessor: 'parcela_broj', width: 100 },
            { Header: i18n.t('Seedlings'), accessor: 'broj_cokota', width: 80 },
            { Header: i18n.t('Crop'), accessor: 'kultura', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={cropType} /> },
            { Header: i18n.t('Sort/Hybrid'), accessor: 'kultura_sorta' },
            { Header: i18n.t('Crop group'), accessor: 'kultura_grupa', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={cropGroupType} /> },
            { Header: i18n.t('Crop subgroup'), accessor: 'kultura_podgrupa', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={cropSubgroupType} /> },

            {
                Header: i18n.t('Season'), accessor: 'layer',
                Filter: ({ filter, onChange }) => {
                    return <ReactSelect
                        filter
                        isMulti
                        name="label"
                        onChange={value => { onChange(value) }}
                        defaultValue={layerType.filter((element) => {
                            if (layers.includes(this.state.user_layer)) return element.value === this.state.user_layer;
                        })}
                        closeMenuOnSelect={false}
                        options={layerType}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
                }
            },
            // { Header: i18n.t('Local government'), accessor: 'kat_opstina', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={localGovType} /> },
            { id: 'has_activity', Header: i18n.t('Has activities'), accessor: d => d.has_activity, Cell: ({ value }) => value != 0 ? i18n.t('Yes') : i18n.t('No'), width: 75 },
            { Header: i18n.t('Type of production'), accessor: 'vrsta_proizvodnje', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={productionType} /> },
            // { id: 'deo_table', Header: i18n.t('Part of the field'), accessor: d => d.deo_table, Cell: ({ value }) => value != 0 ? i18n.t('Yes') : i18n.t('No'), width: 60 },
            // { Header: i18n.t('Main field'), accessor: 'glavna_tabla', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={mainFieldType} /> },
            { Header: i18n.t('Sector'), accessor: 'sektor_naziv', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={sectorType} />, width: 60 },
            { id: 'geom_mobile', Header: i18n.t('Geometry'), accessor: d => d.geom_mobile, Cell: ({ value }) => value != null ? i18n.t('Yes') : i18n.t('No') },
            {
                width: 80,
                Header: i18n.t('Map'),
                accessor: 'map',
                Cell: cell => (
                    <div>
                        {cell.row.geom_mobile !== null &&
                            <Button onClick={() => this.handleButtonClick(cell.row)} style={{ backgroundColor: 'transparent', color: 'black', height: '20px', width: '30px', textTransform: 'none' }}>
                                {i18n.t('Show')}
                            </Button>}
                    </div>
                )
            },
            {
                width: 100,
                Header: i18n.t('Forecast'),
                accessor: 'forecast',
                Cell: cell => (
                    <Button onClick={() => this.handleForecast(cell.row)} style={{ backgroundColor: 'transparent', color: 'black', height: '20px', width: '30px', textTransform: 'none' }}>
                        {cell.row.geom_mobile !== null && i18n.t('Forecast')}
                    </Button>
                )
            }
        ];
        const url = this.state.user_layer !== null ? 'field/read' : '';

        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll } = this.state;
        const checkboxProps = {
            selectAll, isSelected, toggleSelection, toggleAll, selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return { style: { backgroundColor: selected ? "#ACDAF1" : "inherit" } };
                }
                return {};
            },
            getTheadFilterProps: () => { return { style: { backgroundColor: '#58B4E5' } }; },
            getTheadTrProps: () => { return { style: { backgroundColor: '#04764e', color: '#FFFFFF' } }; },
        };

        const filterOptions = {
            defaultFiltered: [
                {
                    id: 'layer',
                    value: layerType.filter((element) => {
                        if (layers.includes(this.state.user_layer)) return element.value === this.state.user_layer;
                    })
                }
            ]
        };

        const { classes } = this.props;

        return (
            <div className="page" style={{ overflow: 'scroll' }}>

                {/* ----------------------------------------search and add new field ------------------- */}
                <div className={classes.searchWrap} style={{ minWidth: '1400px' }}>
                    <SearchBar
                        className={classes.searchBar}
                        placeholder={i18n.t('Search by field name')}
                        cancelOnEscape={true}
                        value={this.state.searchBarValue}
                        // onChange={(searchValue) => !searchValue && this.handleSearchBarChange('')}
                        // onRequestSearch={(searchValue) => this.handleSearchBarChange(searchValue)}
                        // onCancelSearch={() => this.handleSearchBarChange('')}
                        onChange={(searchValue) => this.handleSearchBarChange(searchValue)}
                        onCancelSearch={() => this.handleSearchBarChange('')}
                    />

                    <div style={{ display: 'flex' }}>
                        <div style={{ marginRight: '30px' }}>
                            <div style={{ display: 'flex', minWidth: '250px', justifyContent: 'space-between', backgroundColor: 'white', padding: '0 10px 0 10px', border: '1px solid #979797', boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)' }}>
                                <div
                                // style={{ fontSize: '14px' }}
                                >{i18n.t('Total number of fields')}:</div>
                                <div>{this.state.numberOfAllFields}</div>
                            </div>
                            <div style={{ display: 'flex', minWidth: '250px', justifyContent: 'space-between', backgroundColor: 'white', padding: '0 10px 0 10px', border: '1px solid #979797', boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)' }}>
                                <div
                                // style={{ fontSize: '14px' }}
                                >{i18n.t('Total number of filtered fields')}:</div>
                                <div>{this.state.numberOfFilteredFields}</div>
                            </div>
                        </div>

                        <div>
                            <div style={{ display: 'flex', minWidth: '350px', justifyContent: 'space-between', backgroundColor: 'white', padding: '0 10px 0 10px', border: '1px solid #979797', boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)' }}>
                                <div
                                // style={{ fontSize: '14px' }}
                                >{i18n.t('Total area of all fields')}:</div>
                                <div>{this.state.totalAreaAllFields.toFixed(4)} ha</div>
                            </div>
                            <div style={{ display: 'flex', minWidth: '350px', justifyContent: 'space-between', backgroundColor: 'white', padding: '0 10px 0 10px', border: '1px solid #979797', boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)' }}>
                                <div
                                // style={{ fontSize: '14px' }}
                                >{i18n.t('Total area of filtered fields')}:</div>
                                <div>{this.state.totalAreaFilteredFields.toFixed(4)} ha</div>
                            </div>
                        </div>

                    </div>

                    <Button
                        variant="fab"
                        color="primary"
                        aria-label="Add"
                        className={classes.addNewBtn}
                        onClick={() => this.handleAddNewField()}
                    >
                        <AddIcon />
                        {i18n.t('Add field')}
                    </Button>

                </div>

                {/* --------------------------------------------- */}
                {/* ------------------------------- FILTERS ------------------------------------ */}
                <div className={classes.filtersSection} style={{ minWidth: '1400px' }}>


                    <label style={{ fontSize: '14px', marginRight: '20px', cursor: 'pointer' }} htmlFor="numberInput">{i18n.t('Land area')}:</label>
                    <input value={this.state.selectedLandArea} onChange={(e) => this.handleSelectedLandArea(e)} type="number" name='numberInput' id='numberInput' className={classes.numberInput} />

                    <label style={{ fontSize: '14px', marginRight: '20px', cursor: 'pointer' }} htmlFor="parcelInput">{i18n.t('Parcel')}:</label>
                    <input value={this.state.selectedParcel} onChange={(e) => this.handleSelectedParcel(e)} type="text" name='parcelInput' id='parcelInput' className={classes.numberInput} />

                    <label style={{ fontSize: '14px', marginRight: '20px', cursor: 'pointer' }} htmlFor="cropInput">{i18n.t('Crop')}:</label>
                    <input value={this.state.selectedCrop} onChange={(e) => this.handleSelectedCrop(e)} type="text" name='cropInput' id='cropInput' className={classes.numberInput} />

                    <label style={{ fontSize: '14px', marginRight: '20px', cursor: 'pointer' }} htmlFor="sectorInput">{i18n.t('Sector')}:</label>
                    <input value={this.state.selectedSector} onChange={(e) => this.handleSelectedSector(e)} type="text" name='sectorInput' id='sectorInput' className={classes.numberInput} />


                    <button className={classes.resetButton} onClick={this.resetMyFilters}>{i18n.t('Reset filters')}</button>

                    <Button
                        onClick={() => this.setState({ exportWindow: !this.state.exportWindow })}
                        variant="fab"
                        color="secondary"
                        aria-label="add"
                        className={classes.exportButton}
                    // style={{ marginLeft: 'auto !important' }}

                    >
                        <span className="icon-download headerIcon" />
                    </Button>
                </div>

                {/* -----------------------------------TABLE---------------------------------------- */}
                <div style={{ minWidth: '1400px', border: '1px solid #cecece', backgroundColor: '#fff', margin: '0 30px 20px 30px', borderRadius: '3px', boxSizing: 'border-box', overflow: 'hidden', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)', minHeight: 'calc(100vh - 245px)', display: 'flex', flexDirection: 'column' }}>
                    <div className={classes.tableHeader}>
                        <div className={classes.tableHeaderTitle} >Sve proizvodne table</div>
                    </div>

                    {/* ----------------------------------first row------------------------------ */}
                    <div style={{ display: 'flex', alignItems: "center", justifyContent: 'space-between', margin: '0 16px', padding: '15px 0 8px 0', borderBottom: '1px solid #9b9658', fontSize: '14px', color: '#7d7a7a' }}>
                        <div style={{ width: '170px', marginRight: "20px" }}>{i18n.t("Title")}</div>
                        <div style={{ width: '92px', marginRight: "20px" }}>{i18n.t("Land area")}</div>
                        <div style={{ width: '80px', marginRight: "20px" }}>{i18n.t("Parcel")}</div>
                        {/* <div style={{ width: '60px', marginRight: "20px" }}>{i18n.t("Seedlings")}</div> */}
                        <div style={{ width: '80px', marginRight: "20px" }}>{i18n.t("Crop")}</div>
                        <div style={{ width: '100px', marginRight: "20px" }}>{i18n.t("Sort/Hybrid")}</div>
                        <div style={{ width: '80px', marginRight: "20px" }}>{i18n.t("Crop group")}</div>
                        <div style={{ width: '80px', marginRight: "20px" }}>{i18n.t("Crop subgroup")}</div>
                        <div style={{ width: '80px', marginRight: "20px" }}>{i18n.t("Has activities")}</div>
                        <div style={{ width: '80px', marginRight: "20px" }}>{i18n.t("Type of production")}</div>
                        <div style={{ width: '177px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ width: '60px', marginRight: "20px", paddingLeft: '10px' }}>{i18n.t("Edit")}</div>
                            <div style={{ width: '60px', marginRight: "20px" }}>{i18n.t("Delete")}</div>
                            <div style={{ width: '60px', marginRight: "20px" }}>{i18n.t("Map")}</div>
                            {/* <div style={{ width: '60px', marginRight: "20px" }}>{i18n.t("Forecast")}</div> */}
                            {/* <div style={{ width: '60px', marginRight: "20px" }}>{i18n.t("Forecast")}</div> */}
                        </div>
                    </div>

                    {/* ----------------------------------------------------------- */}
                    {/* --------------------------Filtered Parcels------------------------ */}
                    {this.state.filteredFields && this.state.filteredFields.slice(this.state.currentPage * this.state.itemsPerPage, (this.state.currentPage + 1) * this.state.itemsPerPage).map((item, i) => (
                        <div key={i} style={{ display: 'flex', alignItems: "center", justifyContent: 'space-between', margin: '0 16px', borderBottom: '1px solid #d2d2d2' }}>
                            <div style={{ width: '170px', marginRight: "20px" }}>{item.naziv}</div>
                            <div style={{ width: '92px', marginRight: "20px" }}>{item.povrsina}</div>
                            <div style={{ width: '80px', marginRight: "20px" }}>{item.parcela_broj}</div>
                            {/* <div style={{ width: '60px', marginRight: "20px" }}>{item.broj_cokota}</div> */}
                            <div style={{ width: '80px', marginRight: "20px" }}>{item.kultura}</div>
                            <div style={{ width: '100px', marginRight: "20px" }}>{item.sorte.slice(0, 3).map((sorta, index) => {

                                if (item.sorte.length > 3 && index === 2) {
                                    return <Tooltip2
                                        key={index}
                                        placement="right"
                                        title={
                                            <div style={{ fontSize: '14px', padding: '10px' }}>
                                                <div style={{ borderBottom: '1px solid white', marginBottom: '10px', paddingBottom: '10px' }}>Sve sorte/hibridi:</div>
                                                {item.sorte.map((sorta, index) => {
                                                    return <div key={index}>{sorta.naziv}</div>
                                                })}
                                            </div>
                                        }>
                                        <div style={{ color: 'rgb(88, 181, 230)' }}>
                                            ...prikaži više
                                        </div>
                                    </Tooltip2>
                                } else {

                                    return <div key={index}>{sorta.naziv}</div>
                                }


                            })}</div>
                            <div style={{ width: '80px', marginRight: "20px" }}>{item.kultura_grupa}</div>
                            <div style={{ width: '80px', marginRight: "20px" }}>{item.kultura_podgrupa}</div>
                            <div style={{ width: '80px', marginRight: "20px" }}>{item.has_activity === "0" ? i18n.t("No") : i18n.t("Yes")}</div>
                            <div style={{ width: '80px', marginRight: "20px" }}>{item.vrsta_proizvodnje}</div>

                            <div style={{ minWidth: '177px', display: 'flex', justifyContent: 'flex-start' }}>
                                <Tooltip2 title={item.has_radni_nalog_redovi ? i18n.t("Has rows on activity") : i18n.t("Edit")}>
                                    <span>
                                        <IconButton aria-label="Edit"
                                            style={{ marginLeft: '-6px' }}
                                            onClick={() => this.setState({ editItem: item }, () => {
                                                this.setState({ editWindow: true })
                                            })}
                                            disabled={item.has_radni_nalog_redovi}
                                        >
                                            <Icons.Edit fontSize="large" />
                                        </IconButton>
                                    </span>
                                </Tooltip2>
                                <Tooltip2 title={i18n.t("Delete")}>
                                    <IconButton aria-label="Delete"
                                        onClick={() => {
                                            this.setState({ deleteItemId: item.id }, () => {
                                                this.setState({ deleteWindow: true })
                                            })

                                        }}
                                    >
                                        <Icons.DeleteOutline fontSize="large" />
                                    </IconButton>
                                </Tooltip2>
                                {item.geom_mobile && <Tooltip2 title={i18n.t("Map")}>
                                    <IconButton aria-label="Map"
                                        onClick={() => this.handleMapClick(item.id, item.naziv, item.id_sezona, item.label_sezona)}
                                    >
                                        <Icons.Map fontSize="large" />
                                    </IconButton>
                                </Tooltip2>}
                                {/* {item.geom_mobile && <Tooltip2 title={i18n.t("Forecast")}>
                                {/* {item.geom_mobile && <Tooltip2 title={i18n.t("Forecast")}>
                                    <IconButton aria-label="Forecast"
                                        onClick={() => this.handleForecastClick(item.id, item.naziv)}
                                    >
                                        <Icons.CloudCircle fontSize="large" />
                                    </IconButton>
                                </Tooltip2>} 
                                </Tooltip2>} */}
                            </div>
                        </div>
                    ))}

                    <ReactPaginate
                        pageCount={this.state.pageCount}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        onPageChange={this.handlePageChange}
                        containerClassName={classes.paginationContainer}
                        activeClassName={classes.active}
                        previousLabel={"<"}
                        nextLabel={">"}
                        forcePage={this.state.currentPage}
                    />
                </div>

                {this.state.exportWindow && <Export
                    export={this.state.exportWindow}
                    onTextChange={({ title }) => { this.setState({ docTitle: title }); }}
                    handleClose={this.handleCloseExportWindow}
                    exportDoc={this.exportFunction} />}
                {this.state.addNewField && <AddField add={this.state.addNewField} edit={false} layer={this.state.user_layer} handleClose={this.handleCloseAddNewField} handleCloseArchived={this.handleClickArchived} data={[]} />}
                {this.state.deleteWindow && <Delete delete={this.state.deleteWindow} handleClose={this.handleCloseDeleteWindow} deleteItem={this.handleDeleteItem} />}
                {this.state.editWindow && <AddField add={true} edit={true} layer={this.state.user_layer} handleClose={this.handleCloseEditWindow} handleCloseArchived={this.handleClickArchived} data={this.state.editItem} />}
                {this.state.meteoWindow && <MeteoDialog open={this.state.meteoWindow} handleClick={this.handleMeteoWindowClick} row={this.state.row} />}


                {/* ----------------------------------------------------------- */}

                {/* <div className="table-header">
                    <Fab onClick={() => this.onSearchClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button">
                        <span className="icon-search headerIcon" />
                    </Fab>
                    <Fab onClick={() => { this.handleClick(); }}
                        color="secondary"
                        aria-label="add"
                        className="action-button">
                        <span className="icon-Plus headerIcon" />
                    </Fab>
                    {this.state.selection.length === 1 ?
                        <Fab onClick={() => { this.onEditClick(); }}
                            color="secondary"
                            aria-label="edit"
                            className="action-button">
                            <span className="icon-Izmeni1 headerIcon" />
                        </Fab>
                        : null
                    }
                    {this.state.selection.length > 0 ?
                        <Fab onClick={() => this.onDeleteClick()}
                            color="secondary"
                            aria-label="add"
                            className="action-button">
                            <span className="icon-Kanta headerIcon" />
                        </Fab>
                        : null
                    }
                    <Button
                        onClick={() => this.onExportClick()}
                        variant="fab"
                        color="secondary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-download headerIcon" />
                    </Button>
                </div>
                <CheckboxTable ref={r => (this.checkboxTable = r)}
                    manual
                    data={this.state.user_layer !== null ? this.state.data : []}
                    paging={true}
                    columns={columns}
                    keyField={'id'}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    pages={this.state.totalPages}
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => { this.getData(url, state, instance); }}
                    loading={this.state.loading}
                    className="-striped -highlight"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                    {...filterOptions}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#58B4E5 ',
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#58B4E5 ',
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                /> */}

                {this.state.export && <Export
                    export={this.state.export}
                    onTextChange={({ title }) => { this.setState({ docTitle: title }); }}
                    handleClose={this.onExportClick}
                    exportDoc={this.exportFunction} />
                }
                {this.state.add && <AddField add={this.state.add} edit={this.state.edit} layer={this.state.user_layer} handleClose={this.handleClick} handleCloseArchived={this.handleClickArchived} data={this.state.selectedRow} />}
                {this.state.delete && <Delete delete={this.state.delete} handleClose={this.onDeleteClick} deleteItem={this.deleteItem} />}
                {this.state.meteo && <MeteoDialog open={this.state.meteo} handleClick={this.handleMeteoClick} row={this.state.row} />}
            </div>
        );
    }
}

function FilterSelect(props) {
    return <Select
        isMulti
        name="naziv"
        onChange={value => { props.onChange(value) }}
        closeMenuOnSelect={false}
        options={props.type}
        className="multi-select-in-filter"
        classNamePrefix="select"
        placeholder={i18n.t('Select')}
    />
}

export default withStyles(styles, { withTheme: true })(connect(
    // mapStateToProps,
    // mapDispatchToProps
)(ViewField))