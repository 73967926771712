import React from "react"
import i18n from '../../../i18n/i18n';
import styles from './rows.module.css'
import { Checkbox, Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, FormGroup, FormControlLabel } from "@material-ui/core";

export default class Rows extends React.Component {
    constructor(props) {
        super(props)

        this.contentRef = React.createRef()
        this.state = {
            editRows: this.props.rows.find(rows => rows.id_table === props.id_table).redovi,
            allChecked: !this.props.rows.some(row => row.red_odabran === undefined || row.red_odabran === false)
        }
    }

    makeRowTableChunks() {
        let rowChunks = []
        let chunkLength = Math.floor(this.state.editRows.length / 2) + 1
        let i = 0;

        while (i < this.state.editRows.length) {
            rowChunks.push(this.state.editRows.slice(i, i + chunkLength))
            i += chunkLength
        }

        return rowChunks
    }

    onCheckBoxChange(row_no) {
        const updateIndex = this.state.editRows.findIndex(row => row.red_broj_reda === row_no)
        let updateRow = { ...this.state.editRows[updateIndex] }
        updateRow.red_odabran = !updateRow.red_odabran

        const newRows = [
            ...this.state.editRows.slice(0, updateIndex),
            updateRow,
            ...this.state.editRows.slice(updateIndex + 1)
        ]

        this.setState({
            editRows: newRows
        })
    }

    toggleSelectAll() {
        this.setState((prev) => {
            return { allChecked: !prev.allChecked }
        }, () => {
            let copyRows = this.state.editRows
            copyRows.forEach(row => {
                row.red_odabran = this.state.allChecked
            })
            this.setState({ editRows: copyRows})
        })

    }

    componentDidMount() {
        if (this.contentRef.current) {
            this.contentRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    }

    render() {
        return (
            <div style={{ padding: 20 }}>
                <Dialog open={this.props.open}
                    onClose={this.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth={"md"}
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle disableTypography={true} style={{ background: '#04764e', display: 'flex' }} className="responsive-dialog-title">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{}}>{i18n.t('Pick rows')}
                            </div>
                        </div>
                        <IconButton onClick={
                            () => {
                                this.props.handleClose()
                            }
                        }>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent style={{ display: 'grid' }}>
                        <div className="arrange_seedlings_wrapper" style={{ margin: '20px', minHeight: '500px' }} ref={this.contentRef}>
                            <div id="content">
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox
                                        checked={this.state.allChecked}
                                        onChange={() => this.toggleSelectAll()}
                                        disabled={!this.props.isEdit}></Checkbox>} label={i18n.t('Pick all rows')}
                                        style={{ width: '256px', marginTop: '10px', marginLeft: '10px', marginBottom: '5px' }}></FormControlLabel>
                                </FormGroup>
                                                          
                                <div className={styles.rows_sorts_tables}>
                                    {
                                        this.makeRowTableChunks().map(rowsChunk => (
                                            <table style={{ marginRight: '32px' }}>
                                                <tr style={{ borderBottom: '2px solid rgb(118, 118, 118)' }}>
                                                    <th>{i18n.t('Row number label')}</th>
                                                    <th>{i18n.t('Sort/Hybrid')}</th>
                                                    <th>{i18n.t('Number of seedlings label full')}</th>
                                                    <th></th>
                                                </tr>
                                                {
                                                    rowsChunk.map(row => {
                                                        return (
                                                            <tr key={row.id}>
                                                                <td style={{
                                                                    textAlign: 'center',
                                                                    fontSize: '16px',
                                                                    fontWeight: '550',
                                                                    width: '50px'
                                                                }}>{row.red_broj_reda}</td>
                                                                <td style={{ width: '200px', textAlign: 'center', fontSize: '16px' }}>{row.kultura_sorta !== undefined ? row.kultura_sorta.naziv : ''}</td>
                                                                <td style={{
                                                                    textAlign: 'center',
                                                                    fontSize: '16px',
                                                                    fontWeight: '550',
                                                                    width: '50px'
                                                                }}
                                                                >{row.red_broj_sadnica}</td>
                                                                <td>
                                                                    <div className={styles.checkboxWrapper}>
                                                                        <input type="checkbox"
                                                                            id="myCheck"
                                                                            checked={row.red_odabran}
                                                                            onChange={() => this.onCheckBoxChange(row.red_broj_reda)} />
                                                                        <span class={styles.custom_checkbox}
                                                                            onClick={() => this.onCheckBoxChange(row.red_broj_reda)}
                                                                        ></span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </table>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions style={{ marginBottom: '16px', marginRight: '16px' }}>
                        <Button style={{ marginTop: '20px', marginLeft: "32px" }} variant="contained" color="primary"
                            onClick={() => this.props.handleClose()}>
                            {i18n.t('Back')}
                        </Button>{
                            this.props.isEdit && <Button style={{ marginLeft: '10px', marginTop: '20px', marginLeft: "auto" }} variant="contained" color="primary"
                                onClick={() => {
                                    this.props.handleRowsObj(this.props.id_table, this.state.editRows)
                                }}>
                                {i18n.t('Pick rows')}
                            </Button>
                        }
                    </DialogActions>
                </Dialog>
            </div>

        )
    }

}





