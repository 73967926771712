import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import i18n from '../../../i18n/i18n';
import Select from 'react-select';
import { Fab } from '@material-ui/core';
import { wentWrong } from "../../../utils/ToastConfig";
import axios from '../../../utils/AxiosWrapper';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import { Delete } from '../../../utils/Delete';
import ReactSelect from './../../../utils/ReactSelect';
import { Button, IconButton } from '@material-ui/core';
import { Export } from '../../../utils/Export';
import MeteoDialog from './../../meteoblue/MeteoDialog';
import AddMachine from './AddMachine';
import { forEach } from 'lodash';
import c from 'config';
var FileSaver = require('file-saver');

const styles = theme => ({
    primaryMain: {
        backgroundColor: theme.palette.primary.main,
    },
});
const CheckboxTable = checkboxHOC(ReactTable);;

export default class ViewField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            machineGroupType: [], 
            machineSubgroupType: [],
            machineType: [],
            showFilters: false, loading: true,
            page: 0, pageSize: 25, sorted: null, filtered: null,
            selection: [], selectAll: false, selectedRow: [],
            totalPages: 0, totalFields: 0,
            add: false, edit: false, delete: false,
            data: [],
            add: this.props.add, edit: this.props.edit,

        };
        this.toggleSelection = this.toggleSelection.bind(this);
    }

    componentDidMount() {
        localStorage.removeItem('row');

        axios.get('api/season/drop_down')
            .then(res => {
            })
            .catch(err => { console.log(err); })

    }

    getData(url, state, instance) {
        this.setState({ loading: true, data: [] });
        axios.get(`api/${url}`, {
            params: { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: state.filtered }
        })
            .then(res => {
                this.setState({
                    data: res.data.data,
                    totalPages: res.data.total_pages,
                    totalFields: res.data.total,
                }, () => {
                    if (this.state.user_layer !== null) this.setState({ loading: false })
                });
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 1 (getData): ', error);
            })
    }

    getByURL(url, obj) {
        axios.get(`api/${url}`)
            .then(res => this.setState({ [obj]: res.data.data }))
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 2 (getByURL): ', error, ', URL: ', url);
            })
    }

    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true, selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => { selection.push(item._original.id); });
        }
        this.setState({ selectAll, selection });
    };

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection], selectedRow = null;
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) selection = [...selection.slice(0, keyIndex), ...selection.slice(keyIndex + 1)];
        else selection.push(key);
        if (selection.length == 1) selectedRow = this.state.data.filter((el) => el.id == selection)[0];
        this.setState({ selection, selectedRow });
    };

    handleClick = () => {
        if (this.state.edit == true && this.state.add == false) this.setState({ edit: false, selectedRow: [] });
        else if (this.state.selection.length > 0 && this.state.add == false && this.state.edit == false) this.setState({ selectedRow: [] });
        this.setState({ add: !this.state.add }, () => {
            if (this.state.add === false) this.checkboxTable.wrappedInstance.fireFetchData();
            if (this.state.add == true && this.state.edit == false) this.setState({ selection: [], selectAll: false, });
        });
    };

    handleClickArchived = () => {
        this.setState({ selection: [], selectAll: false, })
    };

    handleMeteoClick = () => { this.setState({ meteo: !this.state.meteo }) };

    deleteItem = () => {
        axios.post(`api/machine/delete`, { selection: this.state.selection })
            .then(res => {
                this.checkboxTable.wrappedInstance.fireFetchData();
                this.setState({ selection:[], delete: false });
            })
            .catch(err => {
                this.setState({ delete: false });
                wentWrong(i18n.t('You cannot delete a implement that is in an activity!'))
            })
    }

    isSelected = key => { return this.state.selection.includes(key); };
    onSearchClick = () => this.setState({ showFilters: !this.state.showFilters });
    onEditClick() { this.setState({ add: !this.state.add, edit: true }); };
    onTableClick = () => this.props.history.push('/field');
    onDeleteClick = () => { this.setState({ delete: !this.state.delete }); };
    onExportClick = () => { this.setState({ export: !this.state.export }); };
    onMapClick = () => this.props.history.push('/maps');

    exportFunction = () => {
        const table = this.checkboxTable.getWrappedInstance();
        this.exportDocument(this.state.docTitle, table.state, 'api/field/read', { potpisan: this.state.potpisan, odbijen: this.state.odbijen });
        this.setState({ export: !this.state.export, docTitle: null });
    };

    async exportDocument(title, state, url, filters) {
        let params = { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: state.filtered, filename: title };
        if (filters) params = { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: state.filtered, filename: title, filters };

        try {
            const response = await axios({ url: url, method: 'GET', responseType: 'blob', params: params });
            return FileSaver.saveAs(new Blob([response.data]), `${title || 'file'}.xlsx`);
        } catch (err) {
            return console.log(err);
        }
    };

    handleButtonClick = (cell) => {
        let row = { id: cell._original.id, naziv: cell.naziv };
        localStorage.setItem('row', JSON.stringify(row));
        this.onMapClick();
    };

    handleForecast = (cell) => {
        let row = { id: cell._original.id, naziv: cell.naziv };
        this.setState({ row, meteo: true });
    };

    render() {
        let machineGroupType = this.state.machineGroupType.map((item) => { return { label: item.naziv, value: item.id } });
        let machineSubgroupType = this.state.machineSubgroupType.map((item) => { return { label: item.naziv, value: item.id } });
        let machineType = this.state.machineType.map((item) => { return { label: item.naziv, value: item.id } });

        const columns = [
            { Header: i18n.t('Title'), accessor: 'naziv', width: 100 },
            { Header: i18n.t('Model'), accessor: 'pogonska_masina', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={machineType} />, width: 200  },
            { Header: i18n.t('Licence number'), accessor: 'registarska_oznaka', width: 150},
            { Header: i18n.t('Implements group'), accessor: 'grupa_naziv', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={machineGroupType} />, width: 150  },
            { Header: i18n.t('Implements subgroup'), accessor: 'podgrupa_naziv', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={machineSubgroupType} />, width: 150  },
            { Header: i18n.t('GPS provider'), accessor: 'gps_provajder_naziv', width: 150},
            { Header: i18n.t('GPS device number'), accessor: 'gps_broj_uredjaja', width: 150},
            
        ];
        const url = this.state.user_layer !== null ? 'machine/get_all' : '';

        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll } = this.state;
        const checkboxProps = {
            selectAll, isSelected, toggleSelection, toggleAll, selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return { style: { backgroundColor: selected ? "#ACDAF1" : "inherit" } };
                }
                return {};
            },
            getTheadFilterProps: () => { return { style: { backgroundColor: '#58B4E5' } }; },
            getTheadTrProps: () => { return { style: { backgroundColor: '#04764e', color: '#FFFFFF' } }; },
        };

        const filterOptions = {
            defaultFiltered: [
                    {
                   
                }
            ]
        };

        return (
            <div className="page">
                <div className="table-header">
                    <Fab onClick={() => this.onSearchClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button">
                        <span className="icon-search headerIcon" />
                    </Fab>
                    <Fab onClick={() => this.handleClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button">
                        <span className="icon-Plus headerIcon" />
                    </Fab>
                    {this.state.selection.length === 1 ?
                        <Fab onClick={() => this.onEditClick()}
                            color="secondary"
                            aria-label="edit"
                            className="action-button">
                            <span className="icon-Izmeni1 headerIcon" />
                        </Fab>
                        : null
                    }
                    {this.state.selection.length > 0 ?
                        <Fab onClick={() => this.onDeleteClick()}
                            color="secondary"
                            aria-label="add"
                            className="action-button">
                            <span className="icon-Kanta headerIcon" />
                        </Fab>
                        : null
                    }
                    
                    {/*sakrio samo dugme jer ne radi dobro. Janko
                     <Button
                        onClick={() => this.onExportClick()}
                        variant="fab"
                        color="secondary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-download headerIcon" />
                    </Button> */}
                </div> 
                <CheckboxTable ref={r => (this.checkboxTable = r)}
                    manual
                    data={this.state.user_layer !== null ? this.state.data : []}
                    paging={true}
                    columns={columns}
                    keyField={'id'}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    pages={this.state.totalPages}
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => { this.getData(url, state, instance); }}
                    loading={this.state.loading}
                    className="-striped -highlight"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                    {...filterOptions}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#58B4E5 ',
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#58B4E5 ',
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                />
                {this.state.export && <Export
                    export={this.state.export}
                    onTextChange={({ title }) => { this.setState({ docTitle: title }); }}
                    handleClose={this.onExportClick}
                    exportDoc={this.exportFunction} />
                }
                {this.state.add && <AddMachine add={this.state.add} edit={this.state.edit} handleClose={this.handleClick} data={this.state.selectedRow} />}
                {this.state.delete && <Delete delete={this.state.delete} handleClose={this.onDeleteClick} deleteItem={this.deleteItem} />}
                {this.state.meteo && <MeteoDialog open={this.state.meteo} handleClick={this.handleMeteoClick} row={this.state.row} />}
            </div>
        );
    }
}

function FilterSelect(props) {
    return <Select
        isMulti
        name="naziv"
        onChange={value => { props.onChange(value) }}
        closeMenuOnSelect={false}
        options={props.type}
        className="multi-select-in-filter"
        classNamePrefix="select"
        placeholder={i18n.t('Select')}
    />
}